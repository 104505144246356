import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import SideBarTest from "../../../components/SideBarTest";

const { RangePicker } = DatePicker;
const cookies = new Cookies();

const PropertyStatusManager = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [data, setData] = useState([]);

  return (
    <SideBarTest>
      <div className="bg-default-background h-screen overflow-auto overflow-y-hidden">
        <div className=" md:px-10 py-4">
          <Breadcrumb className="text-lg">
            <Breadcrumb.Item
              className="hover:text-black font-semibold cursor-pointer "
              onClick={() => navigate("/dashboard")}
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="hover:text-black  font-semibold  cursor-pointer"
              onClick={() => navigate("/manage/manageProperty")}
            >
              Property Master
            </Breadcrumb.Item>
            <Breadcrumb.Item className=" font-semibold ">
              Property Status Manager
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className=" bg-light_yellow  py-3 px-5  mt-4 font-semibold text-lg rounded-md">
            Property Transfer
          </div>
          <div className="bg-white  mt-2 px-10 py-4  rounded-md">
            <div className="text-lg font-semibold">Old Property Details </div>
            <div className="grid lg:grid-cols-2 xl:grid-cols-3  gap-x-10 gap-y-5 mt-4">
              <div className="grid grid-cols-3  font-semibold ">
                <div>Property Name</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Scheme</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Property Name</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Section</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Property Type</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Building Type</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Property Status</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Allotee Name</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
              <div className="grid grid-cols-3  font-semibold ">
                <div>Allotee’s Father Name</div>
                <div>:</div>
                <div>
                  <Input placeholder="Enter Here" />
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-md px-5 py-4 bg-white mt-4">
            <div className="text-lg font-semibold">Section Name</div>
            <div className="grid w-1/3 gap-y-5 mt-10">
              <div className="grid grid-cols-2 ">
                <div className="text-lg">Status *</div>
                <div>
                  <Select
                    defaultValue="lucy"
                    // onChange={handleChange}
                    className="rounded-md"
                    style={{
                      width: 155,
                    }}
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "disabled",
                        disabled: true,
                        label: "Disabled",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 ">
                <div className="text-lg">Remark</div>
                <div>
                  <Select
                    defaultValue="lucy"
                    // onChange={handleChange}
                    className="rounded-md"
                    style={{
                      width: 155,
                    }}
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "disabled",
                        disabled: true,
                        label: "Disabled",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="text-lg">Effective Date *</div>
                <div>
                  <DatePicker />
                </div>
              </div>
            </div>
            <div className="mt-16 flex space-x-4">
              <div className="bg-default_yellow cursor-pointer px-4 py-2 rounded-md hover:text-gray-600 hover:bg-yellow-200">
                <span className="text-black font-semibold text-lg px-2">
                  Update
                </span>
              </div>
              <div className="font-semibold text-lg cursor-pointer mt-2 text-gray-500 hover:text-black">
                Reset to Blanks
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideBarTest>
  );
};

export default PropertyStatusManager;
