import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BarChart } from "./BarChart";
import { data } from "./ChartData";

const LineChart = ({ pieData, total }) => {
  const [width, setWidth] = useState();
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setWidth(250);
    } else if (window.innerWidth > 600 && window.innerWidth < 900) {
      setWidth(350);
    } else if (window.innerWidth < 600 && window.innerWidth > 400) {
      setWidth(200);
    } else if (window.innerWidth < 400) {
      setWidth(100);
    } else {
      setWidth(500);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  useEffect(() => {
    handleResize();
  }, []);
  return (
    <div className="flex ">
      <div className="bg-white  w-auto rounded-lg px-10 pt-4 text-xl font-bold">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl">Weekly cases</h1>
          <span className="font-normal text-base">view more</span>
        </div>

        <div className="">
          <div className="md:flex justify-center h-[270px] xl:h-[270px]   items-center  ">
            <BarChart data={data} width={width} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineChart;
