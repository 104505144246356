/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { BsFillFolderFill } from "react-icons/bs";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const cookies = new Cookies();

const ManageProperty = () => {
  const navigate = useNavigate();
  console.log(window.location.pathname);
  const propertyMaster = [
    {
      name: "New Property",
      link: "newProperty",
    },
    {
      name: "Property Transfer",
      link: "propertyTransfer",
    },
    {
      name: "Update Status",
      link: "updateStatus",
    },
    {
      name: "Update Info",
      link: "updateInfo",
    },
    {
      name: "Registration Form",
      link: "registrationForm",
    },
    {
      name: "Offline Payment",
      link: "offlinePayment",
    },
    {
      name: "Stop Finance Plan",
      link: "stopFinancePlan",
    },
    {
      name: "Clerk Replacement",
      link: "clerkReplacement",
    },
    {
      name: "Rules and Regulations",
      link: "rulesAndRegulations",
    },
    {
      name: "Scheme User Mapping",
      link: "schemeUserMapping",
    },
    {
      name: "Delete Property",
      link: "deleteProperty",
    },
    {
      name: "Update Cloud Payment",
      link: "updateCloudPayment",
    },
  ];
  const alloteeMaster = [
    {
      name: "New Allotee (Mutation) New Allotment",
      link: "newAlloteeAllotment",
    },
    {
      name: "Allotment Cancellation/Restoration",
      link: "allotmentCancellation",
    },
  ];
  const demands = [
    {
      name: "Regular",
      link: "regular",
    },
    {
      name: "Finance",
      link: "finance",
    },
    {
      name: "Other",
      link: "other",
    },
    {
      name: "Relaxtion",
      link: "relaxtion",
    },
    {
      name: "Daily",
      link: "daily",
    },
  ];
  const collection = [
    {
      name: "Challan Entry (Payment)",
      link: "challanEntry",
    },
    {
      name: "Search Property",
      link: "searchProperty",
    },
    {
      name: "Delete Property",
      link: "deleteProperty",
    },
  ];

  return (
    <>
      <div className="bg-default-background pb-10 px-3 md:px-5 w-full h-screen ">
        <div className="bg-white rounded-md px-16 py-10  ">
          <div className="flex">
            <BsFillFolderFill className="text-xl mt-1" />
            <div className="font-semibold md:text-lg ml-2">Property Master</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-5 mt-3 ">
            {propertyMaster.map((item) => {
              return (
                <div
                  onClick={() => {
                    item.link === "newProperty"
                      ? navigate(item.link)
                      : toast.info("Will be coming soon !!", {
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          style: {
                            backgroundColor: "#FFFDD7",
                            color: "black",
                            fontWeight: "bold",
                          },
                          icon: false,
                          progressStyle: { backgroundColor: "#FFE247" },
                        });
                  }}
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">Allotee Master</div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {alloteeMaster.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">Demands</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3  gap-x-4 gap-y-5 mt-3 ">
            {demands.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">Collection</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-5 mt-3 ">
            {collection.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProperty;
