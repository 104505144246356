import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { InputLabel, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const FaqEdit = () => {
  const navigate = useNavigate();

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const getQuestion = () => {
    axios
      .get(
        `hri_admin/help-faq-detail/${localStorage.getItem(
          "FAQ-QuestionId"
        )}?help_section=${localStorage.getItem("help-type")}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("FAQ question: ", res.data);
        setQuestion(res.data.question);
        setAnswer(res.data.answer);
      })
      .catch((err) => {
        console.log("Error getting faq question detail ", err.response);
      });
  };

  const updateQuestion = () => {
    axios
      .put(
        `hri_admin/help-faq-update/${localStorage.getItem(
          "FAQ-QuestionId"
        )}?help_section=${localStorage.getItem("help-type")}`,
        {
          question: question,
          answer: answer,
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Question Updated Successfully", res.data);
        toast.success("Question Updated Successfully", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate(`/help/${localStorage.getItem("help-type")}/faq`);
      })
      .catch((err) => {
        console.log("Error: ", err.response);
        toast.error("Error Updating Question", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  useEffect(() => {
    getQuestion();
  }, []);

  return (
    <div>
      <div className="bg-white px-5 py-4 m-4 rounded-md z-10">
        <p className="text-2xl text-[#0856B6] font-bold">Edit FAQ</p>
        <div className="space-y-5 my-4">
          <div className="px-4 space-y-2 ">
            <TextField
              className="w-[400px]"
              label="Question"
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>

          <div className="px-4 space-y-2">
            <TextField
              multiline
              fullWidth
              minRows={4}
              label="Answer"
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </div>

          <div className="flex items-center space-x-3 mx-4">
            <Button
              variant="contained"
              endIcon={<DoneAllRoundedIcon />}
              onClick={updateQuestion}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/help/${localStorage.getItem("help-type")}/faq`)
              }
            >
              x Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqEdit;
