import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import ReactPlayer from "react-player";
import axios from "axios";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const AddTutorial = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState([]);
  const [videoPreview, setVideoPreview] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const addTutorial = (e) => {
    e.preventDefault();

    let formField = new FormData();

    formField.append("title", title);
    formField.append("description", description);
    formField.append("tutorial_video", video);
    formField.append("tutorial_video_url", videoUrl);

    axios
      .post(
        `hri_admin/help-tutorial-create?help_section=${localStorage.getItem(
          "help-type"
        )}`,
        formField,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Tutorial Added", res.data);
        toast.success("Tutorial Added", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate(`/help/${localStorage.getItem("help-type")}/tutorial`);
      })
      .catch((err) => {
        console.log("Some Error occurred adding tutorial", err.response.data);
        toast.error("Error occurred adding tutorial", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  // const uploadVideo = (e) => {
  //     console.log(e.target.files);
  //     setVideo(URL.createObjectURL(e.target.files[0]));
  // }

  console.log("video", video);

  return (
    <div>
      <div className="bg-white px-5 py-4 m-2 rounded-md z-10">
        <p className="text-xl text-[#0856B6] font-bold">Add New Tutorial</p>
        <div className="space-y-5 px-4 my-4">
          <div className="space-y-2 flex flex-col">
            <TextField
              type="text"
              label="Tutorial Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="space-y-2 ">
            <TextField
              fullWidth
              multiline
              minRows={3}
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div>
            <TextField
              type="url"
              label="Video URL"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </div>

          <Button
            className="bg-[#0865B6]"
            variant="contained"
            component="label"
          >
            Upload New Video
            <input
              hidden
              accept="video/mp4"
              type="file"
              onChange={(e) => {
                setVideo(e.target.files[0]);
                setVideoPreview(URL.createObjectURL(e.target.files[0]));
              }}
            />
          </Button>

          <ReactPlayer
            url={videoPreview}
            className="react-player"
            width="25%"
            height="25%"
          />

          <div className="flex items-center space-x-3">
            <Button
              variant="contained"
              endIcon={<AddCircleOutlineIcon />}
              onClick={addTutorial}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/help/${localStorage.getItem("help-type")}/tutorial`)
              }
            >
              x Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTutorial;
