import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumb, Table, Drawer, Input, Select, Space } from "antd";
import SideBarTest from "../../../components/SideBarTest";
import moment from "moment";
import { notify } from "../../../App";
import { Radio } from "antd";
import Spinner from "../../Spinner/Spinner";
const cookies = new Cookies();

const ApplicantDetails = () => {
  const navigate = useNavigate();
  const [applicantDetails, setApplicantDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [propertyid, setPropertyId] = useState();
  const [propertyAllotment, setPropertyAllotment] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setPropertyId(e.target.value);
    console.log(e.target.value, "here2");
  };

  const AllotProperty = () => {
    const data = {
      application: searchParams.get("application_id"),
      property: propertyid,
    };
    axios
      .post(`property/property-allocation-create`, data, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        notify({ message: "Property Alloted!!", type: "success" });
        getProperty();
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
    onClose();
  };

  const getDetails = () => {
    axios
      .get(
        `property/user-application-detail/${searchParams.get(
          "application_id"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        setApplicantDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  const getProperty = () => {
    axios
      .get(
        `property/property-allotement-list/${searchParams.get(
          "advertisement_Id"
        )}/${searchParams.get("scheme")}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        setPropertyAllotment(res.data);
        setPropertyId(res.data[0].id);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  useEffect(() => {
    getDetails();
    getProperty();
  }, []);

  return (
    <>
      <SideBarTest>
        <div className=" h-screen  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/advertisementList")}
              >
                Advertisement List
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Applicant Details
              </Breadcrumb.Item>
            </Breadcrumb>
            {loading ? (
              <div className="flex justify-center w-full">
                <Spinner />
              </div>
            ) : (
              <>
                {applicantDetails?.selection ? (
                  <div className="flex justify-end mt-4 space-x-5">
                    <button
                      disabled={applicantDetails?.alloted_property}
                      onClick={showDrawer}
                      className={` text-black font-semibold text-lg px-6 cursor-pointer  py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200 ${
                        applicantDetails?.alloted_property
                          ? "cursor-not-allowed bg-gray-100 hover:bg-gray-200"
                          : "cursor-pointer bg-yellow-100 hover:bg-yellow-200"
                      }`}
                    >
                      <span className="text-black font-semibold text-lg px-2">
                        Property Allotment
                      </span>
                    </button>

                    <button
                      disabled={!applicantDetails?.alloted_property}
                      onClick={() =>
                        window.open(
                          `/AavedanPatra/${searchParams.get("application_id")}`
                        )
                      }
                      className={` text-black font-semibold text-lg px-6 cursor-pointer  py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200 ${
                        !applicantDetails?.alloted_property
                          ? "cursor-not-allowed bg-gray-100 hover:bg-gray-200"
                          : "cursor-pointer bg-yellow-100 hover:bg-yellow-200"
                      }`}
                    >
                      View Allotment
                    </button>
                  </div>
                ) : null}

                <div className="bg-white   px-10 py-4 pb-5 font-semibold text-gray-500 rounded-md mt-4">
                  <div className="grid lg:grid-cols-2 xl:grid-cols-3   text-lg gap-x-10 gap-y-5 mt-4">
                    <div className="grid grid-cols-2">
                      <div>Email</div>
                      <div className="text-gray-700 break-all">
                        {applicantDetails?.user_email}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Selection</div>
                      <div className="text-gray-700">
                        {applicantDetails?.selection
                          ? applicantDetails?.selection
                          : "N/A"}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div>Phone Num.</div>
                      <div className="text-gray-700">
                        {applicantDetails?.phone_no}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Name</div>
                      <div className="text-gray-700">
                        {applicantDetails?.name}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Sex</div>
                      <div className="text-gray-700">
                        {applicantDetails?.sex}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Age</div>
                      <div className="text-gray-700">
                        {applicantDetails?.age}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Aadhaar</div>
                      <div className="text-gray-700">
                        {applicantDetails?.aadhaar
                          ? applicantDetails?.aadhaar
                          : "N/A"}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Pan</div>
                      <div className="text-gray-700">
                        {applicantDetails?.pan}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Annual Income</div>
                      <div className="text-gray-700">
                        {applicantDetails?.annual_income}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Bank Account</div>
                      <div className="text-gray-700">
                        {applicantDetails?.bank_acount}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Bank Name</div>
                      <div className="text-gray-700">
                        {applicantDetails?.bank_name}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>RTGS / IFSC</div>
                      <div className="text-gray-700">
                        {applicantDetails?.bank_rtgs_ifsc}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>DD Date</div>
                      <div className="text-gray-700">
                        {applicantDetails?.dd_date}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Created Date</div>
                      <div className="text-gray-700">
                        {moment(applicantDetails?.created_at).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="mt-4 h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                      <img src={applicantDetails?.image} className="h-40 " />
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </SideBarTest>
      <Drawer
        title="Property Allotment"
        placement="right"
        onClose={onClose}
        footer={
          <div
            onClick={AllotProperty}
            className="bg-yellow-100 items-end cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
          >
            <span className="text-black font-semibold text-lg px-2">
              Property Allotment
            </span>
          </div>
        }
        open={open}
      >
        {propertyAllotment?.length ? (
          <div className="h-full">
            <div>
              <Radio.Group
                defaultValue={propertyid}
                onChange={onChange}
                className="radio-custom space-y-4"
              >
                {propertyAllotment?.map((item) => {
                  return (
                    <Space direction="vertical">
                      <Radio key={item.id} value={item.id}>
                        <div className="w-60 font-semibold flex  items-center justify-center h-10">
                          <span className=" text-gray-500">
                            Property Number :
                          </span>
                          <span className="ml-2  text-gray-700">
                            {item.property_no}
                          </span>
                        </div>
                      </Radio>
                    </Space>
                  );
                })}
              </Radio.Group>
            </div>
          </div>
        ) : (
          <div className="h-full text-xl items-center justify-center font-semibold flex">
            Property Not Found !
          </div>
        )}
      </Drawer>
    </>
  );
};

export default ApplicantDetails;
