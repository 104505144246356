export const data = [
  {
    country: "AD",
    "Live Cases": 58,
    Cases: 151,
  },
  {
    country: "AE",
    "Live Cases": 62,
    "hot dogColor": "hsl(128, 70%, 50%)",
    Cases: 90,
  },
  {
    country: "AF",
    "Live Cases": 4,
    "hot dogColor": "hsl(125, 70%, 50%)",
    Cases: 195,
  },
  {
    country: "AG",
    "Live Cases": 101,
    "hot dogColor": "hsl(329, 70%, 50%)",
    Cases: 107,
  },
  {
    country: "AGa",
    "Live Cases": 101,
    "hot dogColor": "hsl(329, 70%, 50%)",
    Cases: 107,
  },
  {
    country: "AGd",
    "Live Cases": 62,
    "hot dogColor": "hsl(128, 70%, 50%)",
    Cases: 90,
  },
  {
    country: "AGw",
    "Live Cases": 101,
    "hot dogColor": "hsl(329, 70%, 50%)",
    Cases: 107,
  },
];

export const PieData = [
  {
    id: "Applied",
    label: "Applied",
    value: 51,
    color: "hsl(178, 70%, 50%)",
  },
  {
    id: "In-Review",
    label: "In-Review",
    value: 4,
    color: "hsl(299, 70%, 50%)",
  },
  {
    id: "Interview",
    label: "Interview",
    value: 4,
    color: "hsl(75, 70%, 50%)",
  },
  {
    id: "Selected",
    label: "Selected",
    value: 3,
    color: "hsl(193, 70%, 50%)",
  },
];
