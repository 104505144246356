import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumb, Table, Drawer, Input, Select } from "antd";
import SideBarTest from "../../../components/SideBarTest";
import moment from "moment";
import { notify } from "../../../App";
import Spinner from "../../Spinner/Spinner";

const cookies = new Cookies();

const AdvertisementDetails = () => {
  const navigate = useNavigate();
  const [getAdvertisementDetails, setGetAdvertisementDetails] = useState();
  const [searchParam] = useSearchParams();
  const type = searchParam.get("type");

  const id = window.location.pathname.slice(19);

  const [loading, setLoading] = useState(true);

  const checkProperty = () => {
    const id2 = window.location.pathname.slice(19);
    if (type === "Apply") navigate(`/apply/Applyform/${id2}`);
    else {
      axios
        .get(`property/property-ad-list/${id2}`, {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        })
        .then((res) => {
          if (res.data[0]) {
            navigate(
              `/propertyDetailsList/${getAdvertisementDetails?.id}?type=Advertisement`
            );
          } else {
            notify({
              message: res.data.msg,
              type: "error",
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          err.response.data.errors.scheme.forEach((message) => {
            notify({ message: message, type: "error" });
          });
        });
    }
  };

  const getDetails = () => {
    axios
      .get(`property/advertisement-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setGetAdvertisementDetails(res.data);
        console.log(res.data, "here");
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <SideBarTest>
        <div className=" h-screen  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/advertisementList")}
              >
                Advertisement List
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Advertisement Details
              </Breadcrumb.Item>
            </Breadcrumb>
            {loading ? (
              <div className="flex justify-center w-full">
                <Spinner />
              </div>
            ) : (
              <>
                <div className=" bg-light_yellow  flex justify-between py-3 px-5  mt-4 font-semibold text-lg rounded-t-md">
                  <span> Advertisement Details</span>
                  <div
                    onClick={checkProperty}
                    className="hover:bg-gray-400 cursor-pointer px-4 py-1 w-auto shadow-md text-center text-white rounded-md hover:text-gray-600 bg-gray-500"
                  >
                    <span className=" font-semibold  px-2">
                      {type === "Apply" ? "Apply" : "View Property"}
                    </span>
                  </div>
                </div>
                <div className="bg-white   px-10 py-4 pb-5 font-semibold text-gray-500 rounded-b-md">
                  <div className="grid lg:grid-cols-2 xl:grid-cols-3   text-lg gap-x-10 gap-y-5 mt-4">
                    <div className="grid grid-cols-2">
                      <div className="">Scheme Name</div>
                      <div className="text-gray-700">
                        {getAdvertisementDetails?.name}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Start Date</div>
                      <div className="text-gray-700">
                        {moment(getAdvertisementDetails?.start_date).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Completion Date</div>
                      <div className="text-gray-700">
                        {moment(getAdvertisementDetails?.end_date).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Unique Id</div>
                      <div className="text-center text-sm text-gray-700 bg-light_yellow shadow-md px-4 py-1 rounded-xl">
                        {getAdvertisementDetails.unique_id}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="mt-4 h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                      <img
                        src={getAdvertisementDetails?.image}
                        className="h-40 "
                      />
                      {getAdvertisementDetails?.image === null ? "N/A" : null}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </SideBarTest>
    </>
  );
};

export default AdvertisementDetails;
