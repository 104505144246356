import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Cookies from "universal-cookie";
import { useDropzone } from "react-dropzone";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
} from "antd";
import SideBarTest from "../../../components/SideBarTest";
import { notify } from "../../../App";
import { MdCancel } from "react-icons/md";
import moment from "moment";

const cookies = new Cookies();

const ViewProperty = () => {
  const navigate = useNavigate();
  const [propertyDetails, setPropertyDetails] = useState();
  const [loading, setLoading] = useState(false);
  const id = window.location.pathname.slice(17);
  const [searchParam] = useSearchParams();
  const type = searchParam.get("type");
  const url = `property/property-detail/${id}`;
  const getAllData = () => {
    axios
      .get(url, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setPropertyDetails(res.data);

        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  console.log(propertyDetails?.sub_scheme, "here5");

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <SideBarTest>
        <div className=" h-screen  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer"
                onClick={() => {
                  searchParam.get("type") === "Advertisement"
                    ? navigate("/advertisementList")
                    : navigate("/scheme");
                }}
              >
                {searchParam.get("type") === "Advertisement"
                  ? "Advertisement"
                  : "Schemes"}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Property Details
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className=" bg-light_yellow  py-3 px-5  mt-4 font-semibold text-lg rounded-t-md">
              Property Details
            </div>
            <div className="bg-white   px-10 py-4   rounded-b-md">
              <div className="text-lg font-semibold">Section Name</div>
              <div className="grid lg:grid-cols-2 xl:grid-cols-3 font-semibold text-lg text-gray-500  gap-x-10 gap-y-6 mt-4">
                <div className="grid grid-cols-2 ">
                  <div>Scheme </div>
                  <div className="text-gray-700">{propertyDetails?.scheme}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Sub Scheme</div>
                  <div className="text-gray-700">
                    {propertyDetails?.sub_scheme}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Select Draw Details</div>
                  <div className="text-gray-700">
                    {propertyDetails?.sub_scheme}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>SCH-CATCD </div>
                  <div className="text-gray-700">
                    {propertyDetails?.sch_cat_cd}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Property ID</div>
                  <div className="text-gray-700">
                    {propertyDetails?.property_no}
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <div>Property No. </div>
                  <div className="text-gray-700">
                    {propertyDetails?.property_no}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Quota Code</div>
                  <div className="text-gray-700">
                    {propertyDetails?.quota_code}
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div>Section </div>
                  <div className="text-gray-700">
                    {propertyDetails?.section}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Handicaped</div>
                  <div className="text-gray-700">
                    {propertyDetails?.handicaped ? "Yes" : "No"}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Senior Citizen</div>
                  <div className="text-gray-700">
                    {propertyDetails?.sr_citizen ? "Yes" : "No"}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Property Type </div>
                  <div className="text-gray-700">
                    {propertyDetails?.section}
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <div>Building Type </div>
                  <div className="text-gray-700">
                    {propertyDetails?.building_type}
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div>Est.Plot Size (covered)</div>
                  <div className="text-gray-700">
                    {propertyDetails?.final_plot_size}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <p className="mt-4 h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                  <img src={propertyDetails?.image} className="h-40 " />
                </p>
              </div>
            </div>
            <div className="rounded-md px-5 py-4 bg-white mt-4">
              <div className="text-lg font-semibold">Other Details</div>
              <div className="grid lg:grid-cols-2 xl:grid-cols-3 font-semibold text-lg text-gray-500 gap-x-10 gap-y-5 mt-4">
                <div className="grid grid-cols-2 ">
                  <div>Current Allotee</div>
                  <div className="text-gray-700">
                    {propertyDetails?.current_allotee}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Father/Husband Name</div>
                  <div className="text-gray-700">
                    {propertyDetails?.current_allotee_father_husband}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Possession Date</div>
                  <div className="text-gray-700">
                    {propertyDetails?.possesion_date}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Address</div>
                  <div className="text-gray-700">
                    {propertyDetails?.current_allotee_address}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>City</div>
                  <div className="text-gray-700">
                    {propertyDetails?.current_allotee_city}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Pin</div>
                  <div className="text-gray-700">
                    {propertyDetails?.current_allotee_pin}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Registry Date</div>
                  <div className="text-gray-700">
                    {propertyDetails?.registry_date}
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div>Basic Allotee </div>
                  <div className="text-gray-700">
                    {propertyDetails?.basic_allotee}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Allotment No.</div>
                  <div className="text-gray-700">
                    {propertyDetails?.allotment_no}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Bank</div>
                  <div className="text-gray-700">{propertyDetails?.bank}</div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Registration Date</div>
                  <div className="text-gray-700">
                    {propertyDetails?.registry_date}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Reg. Amount </div>
                  <div className="text-gray-700">
                    {propertyDetails?.reg_amount}
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div>Landline No.</div>
                  <div className="text-gray-700">
                    {propertyDetails?.land_line_no}
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-md px-5 py-4 bg-white mt-4">
              <div className="text-lg font-semibold">Other Details</div>
              <div className="grid lg:grid-cols-2 xl:grid-cols-3 font-semibold text-lg text-gray-500 gap-x-10 gap-y-5 mt-4">
                <div className="grid grid-cols-2 ">
                  <div>Land Use</div>
                  <div className="text-gray-700">
                    {propertyDetails?.land_use}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Floor</div>
                  <div className="text-gray-700">{propertyDetails?.floor}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Length</div>
                  <div className="text-gray-700">{propertyDetails?.length}</div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Breadth</div>
                  <div className="text-gray-700">
                    {propertyDetails?.breadth}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Terrace Cost</div>
                  <div className="text-gray-700">
                    {propertyDetails?.terrace_cost}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Extra Land Area</div>
                  <div className="text-gray-700">
                    {propertyDetails?.extra_land_area}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Creation Date</div>
                  <div className="text-gray-700">
                    {propertyDetails?.creation_date}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Fencing Wall Cost</div>
                  <div className="text-gray-700">
                    {propertyDetails?.fencing_wall_cost}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Year of Construction</div>
                  <div className="text-gray-700">
                    {propertyDetails?.construction_yr}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Nazool Property</div>
                  <div className="text-gray-700">
                    {propertyDetails?.nazool_property ? "Yes" : "No"}
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div>Sector</div>
                  <div className="text-gray-700">{propertyDetails?.sector}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Lease Free Hold</div>
                  <div className="text-gray-700">
                    {propertyDetails?.lease_free_hold ? "Yes" : "No"}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Status Flag</div>
                  <div className="text-gray-700">
                    {propertyDetails?.status_flag ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBarTest>
    </>
  );
};

export default ViewProperty;
