import React, { useState } from "react";
import image from "../Images/HomeImage.png";
import { useNavigate } from "react-router";
import axios from "axios";
import { useDispatch } from "react-redux";
import { save } from "../../features/InfoSlice";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../firebase";
import Cookies from "universal-cookie";
import { postRequest } from "../../utils/request";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const Login = () => {
  const cookies = new Cookies();

  const dispatch = useDispatch();
  const [active, setActive] = useState("btn1");

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const [user] = useAuthState(auth);

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(() => {
        let formField = new FormData();
        formField.append("access_token", user?.accessToken);
        postRequest("auth/google/login", formField, "/dashboard");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const navigate = useNavigate();

  //login in
  const [email_login, setEmail_login] = useState("");
  const [password_login, setPassword_login] = useState("");

  const data = {
    email: email_login,
    password: password_login,
  };

  const LoginUser = async (e) => {
    e.preventDefault();

    axios
      .post(`auth/admin/login`, data)
      .then((res) => {
        console.log("response from submitting the form successful", res.data);
        dispatch(
          save({
            user: res.data,
          })
        );
        localStorage.setItem("user", res.data.user.id.toString());
        cookies.set("token", res.data.user.token);
        navigate("/dashboard");
        toast.success("Successfully logged in", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log("ERROR  from update in form", err);
        toast.error("Error logging in", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setValues({
      ...values,
      showPassword2: !values.showPassword2,
    });
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword3 = () => {
    setValues({
      ...values,
      showPassword3: !values.showPassword3,
    });
  };

  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };

  //registeration of the user
  const [email_signup, setEmail_signup] = useState("");
  const [password_signup, setPassword_signup] = useState("");

  const SignupUser = async () => {
    let formField = new FormData();

    formField.append("email", email_signup);
    formField.append("password", password_signup);

    axios
      .post(`auth/user/register`, formField)
      .then((response) => {
        window.location.reload();
        toast.success("Successfully signed in", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log("ERROR in form", err);
        toast.error("Error signing in", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  //Forgot password
  const [userEmail, setUserEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dataToForgetPassword = {
    email: userEmail,
  };

  const ForgotPassword = (e) => {
    e.preventDefault();

    axios
      .post(`auth/user/forgot/password`, dataToForgetPassword)
      .then((res) => {
        cookies.set("token", res.data.token);
        setOtp(res.data.otp);
        setActive("btn4");
        toast.success(`OTP sent to your email`, {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log(err);
        if (userEmail === "") {
          toast.error("Please enter E-mail ID", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        } else {
          toast.error("Error", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        }
      });
  };

  //Verify OTP
  const dataToVerifyOtp = {
    email: userEmail,
    otp: otp,
  };
  const VerifyOtp = (e) => {
    e.preventDefault();

    axios
      .post(`auth/user/forgot/password/verify`, dataToVerifyOtp, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setActive("btn5");
        toast.success("Successfully verified the OTP", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log(err);
        if (otp === "") {
          toast.error("Please enter  OTP", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        } else {
          toast.error("Error", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        }
      });
  };

  //Reset Password
  const dataToResetPassword = {
    password: newPassword,
  };

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const ResetPassword = (e) => {
    e.preventDefault();

    axios
      .post(`auth/user/reset/password`, dataToResetPassword, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setActive("btn1");
        toast.success("Successfully Changed the password", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="bg-white h-screen ">
        <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center pt-10 mx-4 md:mx-7 lg:mx-10 ">
          <div className="md:mt-24 lg:mt-16  ">
            <div className="text-3xl font-semibold ">
              Welcome to Kanpur Development Authority
            </div>
            <p className="text-xl mt-2 text-[#585757] font-semibold">
              Manage as Admin
            </p>
            <img
              src={image}
              className="w-0 md:w-full max-h-[420px]  mt-10 px-4"
              alt=""
            />
            <div className="flex justify-end">
              <div className="text-[#98989C] font-semibold ">
                Powered by Plausibility Solutions
              </div>
            </div>
          </div>

          <div
            className="p-6 md:px-8 md:py-4 lg:px-10 lg:py-6 md:mt-16 mb-10 md:mb-0
                md:w-10/12 border-3 rounded-xl border-[#F4ED3F]"
          >
            {active === "btn1" ? (
              <div className="">
                <p className="text-3xl font-semibold mb-4 md:mb-6 lg:mb-10 ">
                  Login As Admin
                </p>
                <form className="space-y-3 sm:space-y-4 lg:space-y-5">
                  <TextField
                    fullWidth
                    type="email"
                    label="Email ID"
                    placeholder="Enter your Email ID"
                    value={email_login}
                    color="neutral"
                    onChange={(e) => setEmail_login(e.target.value)}
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      label="Password"
                      value={password_login}
                      type={values.showPassword3 ? "text" : "password"}
                      placeholder="Enter Your Password"
                      onChange={(e) => setPassword_login(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            type={values.showPassword ? "text" : "password"}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword3}
                            onMouseDown={handleMouseDownPassword3}
                            edge="end"
                          >
                            {values.showPassword3 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                    />
                  </FormControl>
                  <div className="flex justify-end">
                    <div
                      className="cursor-pointer text-gray-500 hover:text-black"
                      onClick={(e) => setActive("btn3")}
                    >
                      Forgot Password?
                    </div>
                  </div>
                  <div>
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#FFE247", color: "black" }}
                      onClick={LoginUser}
                    >
                      LogIn
                    </Button>
                  </div>

                  <div>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="inherit"
                      onClick={() => setActive("btn2")}
                    >
                      Not a Member? SignUp
                    </Button>
                  </div>
                  <p
                    align="center"
                    className="text-gray-500 font-semibold text-lg lg:pt-4"
                  >
                    The <span style={{ color: "#FFE247" }}>terms of use</span>{" "}
                    and <span style={{ color: "#FFE247" }}>our Policy</span>
                  </p>
                </form>
              </div>
            ) : (
              <></>
            )}

            {active === "btn2" ? (
              <div>
                <p className="text-3xl font-semibold mb-4 md:mb-6 lg:mb-10 ">
                  Sign Up as Admin
                </p>
                <form className="space-y-3 sm:space-y-4 lg:space-y-5">
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    placeholder="Enter your Email ID"
                    value={email_signup}
                    color="neutral"
                    onChange={(e) => setEmail_signup(e.target.value)}
                  />
                  <FormControl color="neutral" variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      label="Password"
                      id="Password"
                      color="secondary"
                      type={values.showPassword ? "text" : "password"}
                      onChange={(e) => setPassword_signup(e.target.value)}
                      endAdornment={
                        <InputAdornment color="#333333" position="end">
                          <IconButton
                            type={values.showPassword ? "text" : "password"}
                            aria-label="toggle password visibility"
                            color="neutral"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                    />
                  </FormControl>

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      label="Confirm Password"
                      id="confirm_password"
                      type={values.showPassword2 ? "text" : "password"}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            type={values.showPassword ? "text" : "password"}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            edge="end"
                          >
                            {values.showPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                    />
                  </FormControl>

                  <div>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={confirmPassword === newPassword}
                      style={{ backgroundColor: "#FFE247", color: "black" }}
                      onClick={SignupUser}
                    >
                      SignUp
                    </Button>
                  </div>
                  <div>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="inherit"
                      onClick={() => setActive("btn1")}
                    >
                      Already have an account? LogIn
                    </Button>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}

            {active === "btn3" ? (
              <div>
                <p className="text-3xl font-semibold mb-4 md:mb-6 lg:mb-10">
                  Forgot Password
                </p>
                <div className="space-y-3 sm:space-y-4 lg:space-y-5">
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    placeholder="Enter your email address"
                    value={userEmail}
                    color="neutral"
                    onChange={(e) => setUserEmail(e.target.value)}
                  />

                  <div className="pt-10">
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#FFE247", color: "black" }}
                      onClick={ForgotPassword}
                    >
                      Send
                    </Button>
                  </div>

                  <div>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="inherit"
                      onClick={() => setActive("btn1")}
                    >
                      Already have an account? LogIn
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {active === "btn4" ? (
              <div>
                <p className="text-3xl font-semibold mb-4 md:mb-6 lg:mb-10 ">
                  Verify OTP
                </p>
                <form className="space-y-3 sm:space-y-4 lg:space-y-5">
                  <TextField
                    fullWidth
                    type="number"
                    label="OTP"
                    placeholder="Enter your OTP to verify"
                    // value={otp}
                    color="neutral"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <div className="pt-10">
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#FFE247", color: "black" }}
                      onClick={VerifyOtp}
                    >
                      Verify
                    </Button>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}

            {active === "btn5" ? (
              <div className="space-y-3 sm:space-y-4 lg:space-y-5">
                <p className="text-3xl mb-5 md:mb-6 lg:mb-10 md:text-3xl lg:text-5xl">
                  New Password
                </p>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Enter your New Password
                  </InputLabel>
                  <OutlinedInput
                    label="Enter your new password"
                    // value={newPassword}
                    type={values.showNewPassword ? "text" : "password"}
                    onChange={(e) => setNewPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          type={values.showNewPassword ? "text" : "password"}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          edge="end"
                        >
                          {values.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    required
                  />
                </FormControl>
                <div className="pt-10">
                  <div>
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#FFE247", color: "black" }}
                      onClick={ResetPassword}
                    >
                      Reset
                    </Button>
                  </div>
                  <div className="mt-4">
                    <Button
                      fullWidth
                      variant="outlined"
                      color="inherit"
                      onClick={() => setActive("btn4")}
                    >
                      Previous
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Login;
