/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import Spinner from "../Spinner/Spinner";
import Scr from "./srceen.png";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Modal } from "antd";
import Nodata from "../../Images/noData.png";
import SideBarTest from "../../components/SideBarTest";

const cookies = new Cookies();

const headButton = [
  {
    name: "Property Allocation",
    number: 16,
    active: false,
  },
  {
    name: "In Court cases",
    number: 24,
    active: true,
  },
  {
    name: "Alloted properties",
    number: 43,
    active: false,
  },
  {
    name: "Disputed properties",
    number: 12,
    active: false,
  },
];

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [jobNumber, setJobNumber] = useState([]);
  const [message, setMessage] = useState("");
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function getData() {
    await axios
      .get("hri_admin/dashboard", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        setData(resp.data.unread_msg);
        setPieData(resp.data.pie_chart);
        setJobTitle(resp.data.job_title);
        setJobNumber(resp.data.job_title_application_no);
        setTotal(resp.data.total_application);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <SideBarTest>
        <div className=" pb-10 h-screen overflow-auto">
          <div className="md:px-4 py-7">
            <div className="grid md:px-4 pb-10  grid-cols-3 gap-y-4 xl:grid-cols-4 gap-x-5 xl:gap-x-10 text-center">
              {headButton.map((item) => {
                return (
                  <div
                    className={`py-2 xl:py-4 rounded-md bg-white cursor-pointer  border-2 shadow-sm${
                      item.active === true
                        ? "text-default_yellow border-2 border-default_yellow"
                        : null
                    }`}
                    key={item.name}
                  >
                    <div
                      className={`font-semibold xl:text-lg text-gray-500
                 ${item.active === true ? "text-yellow-300 " : null}}`}
                    >
                      {item.name}
                    </div>
                    <div
                      className={`font-semibold text-xl xl:text-4xl
                 ${item.active === true ? "text-yellow-300 " : null}}`}
                    >
                      {item.number}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className=" bg-[#EDEBEE]   md:px-4  h-auto ">
              <div className="lg:flex space-y-5 lg:space-y-0 lg:space-x-5 justify-between">
                <div className="pt-4 md:mt-[-24px] l">
                  <LineChart jobTitle={jobTitle} jobNumber={jobNumber} />
                </div>
                <div>
                  <PieChart pieData={pieData} total={total} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="rounded-md p-2 py-4 mx-3 mt-4  bg-white border-gray-200">
                <div className="flex justify-between px-4">
                  <div className="text-lg  font-semibold">
                    <div> Unresolved tickets</div>
                    <div className="text-base mt-2 text-gray-500">
                      Group : <span className="text-gray-800">Support</span>
                    </div>
                  </div>
                  <div className="font-semibold text-default_yellow">
                    view details
                  </div>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
              <div className="rounded-md col-span-2 p-2 mx-3 mt-4 bg-white border-gray-200 text-center">
                Coming Soon
              </div>
            </div>
          </div>

          <Modal
            title=""
            visible={isModalVisible}
            footer={false}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="mt-4">{message}</div>
          </Modal>
        </div>
      </SideBarTest>
    </>
  );
};

export default Home;
