import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Button, Card, CardContent } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import image1 from "../Team Manage/3135715.png";
import Github from "../../Images/Github_logo.png";
import LinkedIn from "../../Images/Linkedin_logo.png";
import SideBarTest from "../../components/SideBarTest";
import { Breadcrumb } from "antd";

const cookies = new Cookies();

const Profile = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [empId, setEmpId] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  // const [status, setStatus] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [country, setCountry] = useState("");
  const [add, setAdd] = useState("");
  const [github_id, setGithub_id] = useState("");
  const [linkedin_id, setLinkedin_id] = useState("");

  async function getData() {
    await axios
      .get(`auth/user/profile`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        console.log(resp.data);
        setEmail(resp.data.user.email);
        setId(resp.data.user.id);
        setName(resp.data.user.profile.name);
        cookies.set("profileName", resp.data.user.profile.name);
        setPhone(resp.data.user.profile.otp_phone);
        setDob(resp.data.user.profile.dob);
        setEmpId(resp.data.user.profile.employee_id);
        setRole(resp.data.user.profile.role);
        setGender(resp.data.user.profile.gender);
        setAbout(resp.data.user.profile.about_you);
        setImage(resp.data.user.profile.image);
        // setStatus(resp.data.user.profile.status);
        setCity(resp.data.user.profile.city);
        setCountry(resp.data.user.profile.country);
        setState(resp.data.user.profile.state);
        setPin(resp.data.user.profile.pin);
        setAdd(resp.data.user.profile.address);
        setGithub_id(resp.data.user.profile.github_id);
        setLinkedin_id(resp.data.user.profile.linkedin_id);
        cookies.set("profileImage", resp.data.user.profile.image);
        cookies.set("otp", resp.data.user.profile.otp);
        cookies.set("id", resp.data.user.profile.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (cookies.get("token")) {
      getData();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <SideBarTest>
      <div className="bg-[#EDEBEE] h-screen overflow-auto">
        <Breadcrumb className="text-lg">
          <Breadcrumb.Item
            className="hover:text-black font-semibold cursor-pointer "
            onClick={() => navigate("/dashboard")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-semibold">Profile</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div className="pt-4 pb-20 px-4  md:px-10 w-full">
            <Card elevation={0}>
              <CardContent>
                <div className="flex flex-row mb-4">
                  <p
                    className="font-semibold text-sky-700 pt-2 pl-4 flex-grow text-xl
                                                     md:text-xl
                                                     lg:text-2xl"
                  >
                    My Profile
                  </p>
                  <div>
                    <Button
                      align="right"
                      className="sm:w-4 md:w-10 lg:w-20"
                      onClick={() => navigate("edit")}
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "10px",
                        color: "rgb(8, 101, 182)",
                      }}
                    >
                      Edit <EditIcon style={{ fontSize: "medium" }} />
                    </Button>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5">
                  <div align="center">
                    <img
                      className=" mt-1.5 h-24 rounded-full"
                      src={image ? image : image1}
                      alt=""
                    />
                  </div>
                  <div className="col-span-4 mx-4">
                    <p className="mt-4 text-2xl md:text-3xl lg:text-4xl font-semibold">
                      {name ? name : "null"}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 text-xl">
                      <div>
                        <p className="text-neutral-400">Email ID</p>
                        <p>{email ? email : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Contact</p>
                        <p>{phone ? phone : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Employee ID</p>
                        <p>{empId ? empId : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Date of Birth</p>
                        <p>{dob ? dob : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Designation</p>
                        <p>{role ? role : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">City</p>
                        <p>{city ? city : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Sate</p>
                        <p>{state ? state : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Pin</p>
                        <p>{pin ? pin : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Address</p>
                        <p>{add ? add : "null"}</p>
                      </div>

                      <div>
                        <p className="text-neutral-400">Gender</p>
                        <p>{gender ? gender : "null"}</p>
                      </div>

                      <div className="col-span-1 md:col-span-2">
                        <p className="text-neutral-400">About You</p>
                        <p>{about ? about : "null"}</p>
                      </div>
                    </div>

                    <div className="flex flex-row gap-4 my-4">
                      <a href={`${linkedin_id}`} target="_blank">
                        <img
                          src={linkedin_id ? LinkedIn : ""}
                          className="w-10 md:w-12 lg:w-12"
                          alt=""
                        />
                      </a>
                      <a href={`${github_id}`} target="_blank">
                        <img
                          src={github_id ? Github : ""}
                          className="w-10 md:w-12 lg:w-12"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </SideBarTest>
  );
};

export default Profile;
