export const Rules = [
  {
    id: 1,
    rule: "भ्लूखंड के मद में देय धनराशि विभिन्‍न बैंकों में स्थित कानपुर विकास प्राधिकरण कोष में जा सकेगी",
  },
  {
    id: 2,
    rule: "भ्रवन/भूखंड के मद में बकाया संपूर्ण धनराशि आवंटन पत्र पंजीकृत डाक से प्रेषित किये पर ९० प्रतिशत धनराशि (० 13,59,000.00) पर ५ प्रतिशत की छूट देय होगी।",
  },
  {
    id: 3,
    rule: "अवन/भूखंड के मद में जमा की जाने वाली धनराशि उपरोक्त तालिका में तिथि के साँथ अंकित है जिससे निर्धारित तिथि के अंदर धनराशि न जमा करने पर नियमानुसार दंड ब्याज देय होगा ३ किस्तों का न करने की दशा में आवंटन ज्र्व॑तः निरस्त हो जायेगा , तथा जमा पंजीकरण धनराशि जब्त कर ली जायेगी",
  },
  {
    id: 4,
    rule: "साईट प्लान के अनुसार यदि क्षेत्रफल में कोई बढ़ोत्तरी/घटोतरी होती कीमत का १९० प्रतिशत अतिरिक्त देय होगा ",
  },
  {
    id: 5,
    rule: "आवंटी को निबंधन के पूर्व भवन / भूखंड के मूल्य का १२ जभ्रत फ्री होल्ड चार्फ़े जमा केरना होगा ",
  },
  {
    id: 6,
    rule: "यदि किसी व से भवन /भूखंड विवादित हो जाता है भी दशा मैं वैकल्पिक्‌ भूखंड/का आवंटन नहीं किया जायेगा एवं जमा धनराशि बिना किसी कटौती के वापस कर दिया जायेग्रा। यदि आवंटी की जम्म वर्ष से अधिक समय तक प्राधिकरण खाते में जमा रहती है तो उस पर तत्समय स्टेट बैंक के बचत खाते ग्रह्मक्रित ब्याज दर तथा एक प्रतिशत अतिरिक्त ब्याज के साथ वापस कर दी जाएगी",
  },
  {
    id: 7,
    rule: "पक्ष द्वारा किन्ही परिस्थितियों में जमा शि वास किये जले प्रा पत्र दिए जाने पर पंजीकरण धनराशि जब्त करते हुए शेष धनराशि वापस की जायेगी",
  },
  {
    id: 8,
    rule: "भ्रूखण्ड के निबन्धन के समय पति/प्रत्री के नाम सुंयुक्त रूप सै/निबन्ध्र्न की कार्यवाही की जायेगी",
  },
  {
    id: 9,
    rule: "आवंटित सम्पत्ति पर प्राधिकरण से नियमानुसार त्र स्वीकृत करने के पश्चात ही निर्माण अनुमन्य होगा एवं निर्माण सम्बंधी सभी नियम व शर्ते आवंटी को मान्य होगी ",
  },
  {
    id: 10,
    rule: "सम्पत्ति का कब्ज़ा के साथ ही दिये जाने का प्राविधान है",
  },
  {
    id: 11,
    rule: "आवंटित सम्पत्ति पर प्राधिकरण से नियमानुसार म्रनचित्र स्वीकृत करने के पश्चात ही निर्माण अनुमन्य होगा एवं निर्माण सम्बंधी सभी नियम व शर्ते आवंटी को मान्य होगी किसी भी विवाद में उपाध्य क्ष कानपुर विकास प्राधिकरण का निर्णय अंतिम व मान्य होगा ",
  },
];

export const PaymentTerms = [
  {
    id: 1,
    type: "Allotment Dues",
    rs: 26000,
    date: "31-12-2022",
    terms: 0,
    pay: 0,
    intrest: 15,
  },
  {
    id: 2,
    type: "EMI",
    rs: 1125000,
    date: "01-03-2023",
    terms: 1,
    pay: 8.95,
    intrest: 10.95,
  },
  {
    id: 3,
    type: "EMI",
    rs: 1089079,
    date: "01-06-2023",
    terms: 23,
    pay: 12,
    intrest: 15,
  },
];

export const InstallmentsPaymentSchedule = [
  {
    id: 1,
    date: "31-12-2022",
    pay: 260000,
    interest: 0,
    total: 260000,
  },
  {
    id: 2,
    date: "01-03-2023",
    pay: 35921,
    interest: 25172,
    total: 61093,
  },
  {
    id: 3,
    date: "01-06-2023",
    pay: 33560,
    interest: 32672,
    total: 66232,
  },
  {
    id: 4,
    date: "01-06-2023",
    pay: 34566,
    interest: 31666,
    total: 66232,
  },
  {
    id: 5,
    date: "01-12-2023",
    pay: 35603,
    interest: 30629,
    total: 66232,
  },
  {
    id: 6,
    date: "01-03-2024",
    pay: 36671,
    interest: 29561,
    total: 66232,
  },
  {
    id: 7,
    date: "01-03-2024",
    pay: 36671,
    interest: 29561,
    total: 66232,
  },
  {
    id: 8,
    date: "01-12-2023",
    pay: 35603,
    interest: 30629,
    total: 66232,
  },
  {
    id: 9,
    date: "01-06-2023",
    pay: 34566,
    interest: 31666,
    total: 66232,
  },
  {
    id: 10,
    date: "01-03-2024",
    pay: 36671,
    interest: 29561,
    total: 66232,
  },
  {
    id: 11,
    date: "01-06-2023",
    pay: 34566,
    interest: 31666,
    total: 66232,
  },
  {
    id: 12,
    date: "01-03-2024",
    pay: 36671,
    interest: 29561,
    total: 66232,
  },
  {
    id: 13,
    date: "01-12-2023",
    pay: 35603,
    interest: 30629,
    total: 66232,
  },
  {
    id: 14,
    date: "01-06-2023",
    pay: 33560,
    interest: 32672,
    total: 66232,
  },
  {
    id: 15,
    date: "01-06-2023",
    pay: 34566,
    interest: 31666,
    total: 66232,
  },
  {
    id: 16,
    date: "01-03-2023",
    pay: 35921,
    interest: 25172,
    total: 61093,
  },
  {
    id: 17,
    date: "01-12-2023",
    pay: 35603,
    interest: 30629,
    total: 66232,
  },
  {
    id: 18,
    date: "01-06-2023",
    pay: 34566,
    interest: 31666,
    total: 66232,
  },
  {
    id: 19,
    date: "01-03-2024",
    pay: 36671,
    interest: 29561,
    total: 66232,
  },
  {
    id: 20,
    date: "01-12-2023",
    pay: 35603,
    interest: 30629,
    total: 66232,
  },
  {
    id: 21,
    date: "01-03-2024",
    pay: 36671,
    interest: 29561,
    total: 66232,
  },
  {
    id: 22,
    date: "01-12-2023",
    pay: 35603,
    interest: 30629,
    total: 66232,
  },
  {
    id: 23,
    date: "01-06-2023",
    pay: 33560,
    interest: 32672,
    total: 66232,
  },
  {
    id: 24,
    date: "01-12-2023",
    pay: 35603,
    interest: 30629,
    total: 66232,
  },
  {
    id: 25,
    date: "01-06-2023",
    pay: 33560,
    interest: 32672,
    total: 66232,
  },
];
