/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";

import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import ManageProperty from "./ManageProperty";
import Reports from "./Reports";
import ManageMaster from "./ManageMaster";
import SideBarTest from "../../components/SideBarTest";

const cookies = new Cookies();

const Manage = () => {
  const navigate = useNavigate();
  console.log(window.location.pathname);
  const topMenuData = [
    {
      name: "Manage Property",
      link: "manageProperty",
    },
    {
      name: "Reports",
      link: "reports",
    },
    {
      name: "Manage Master",
      link: "manageMaster",
    },
  ];

  return (
    <>
      <SideBarTest>
        <div className=" pb-10  w-full h-full ">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3 py-4 px-3 md:px-5 ">
            {topMenuData.map((item) => {
              return (
                <div
                  onClick={() => navigate(item.link)}
                  className={`hover:bg-default_yellow md:text-xl rounded-md text-center text-neutral-400  cursor-pointer hover:text-black py-3 px-4 font-bold 
             ${
               window.location.pathname === "/manage/" + item.link
                 ? "bg-default_yellow text-black"
                 : "bg-white"
             }`}
                  key={item.name}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          {window.location.pathname === "/manage/manageProperty" ? (
            <ManageProperty />
          ) : null}
          {window.location.pathname === "/manage/reports" ? <Reports /> : null}
          {window.location.pathname === "/manage/manageMaster" ? (
            <ManageMaster />
          ) : null}
        </div>
      </SideBarTest>
    </>
  );
};

export default Manage;
