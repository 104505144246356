import React from "react";
import { PieData } from "./ChartData";
import { CustomPie } from "./CustomPie";

const PieChart1 = ({ pieData, total }) => {
  return (
    <div className="flex ">
      <div className="bg-white w- rounded-lg px-10 pt-4 text-xl font-bold">
        <div className="flex justify-between items-center">
          <h1>Total Applications</h1>
          <span>40</span>
        </div>

        <div className="flex justify-center">
          <div className="md:flex justify-center h-[300px] md:h-[300px] items-center w-[35vh] md:w-[40vh] xl:w-[65vh]  ">
            <CustomPie pieData={PieData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart1;
