import axios from "axios";
import React, { useEffect, useState } from "react";
import { notify } from "../App";
import Spinner from "../Pages/Spinner/Spinner";
import {
  InstallmentsPaymentSchedule,
  PaymentTerms,
  Rules,
} from "./AavedanPatraData";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const AavedanPatra = () => {
  const [applicantDetails, setApplicantDetails] = useState();
  const [propertyDetails, setPropertyDetails] = useState();
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const id = window.location.pathname.slice(14);

  const Print = () => {
    //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const getDetails = () => {
    axios
      .get(`property/property-allocation-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setApplicantDetails(res.data.application_detail);
        setPropertyDetails(res.data.property_detail);
        setData(res.data);
        setLoading(true);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      <div className="bg-white">
        <div className="flex justify-end pr-5 pt-5">
          <div
            onClick={Print}
            className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
          >
            <span className="text-black font-semibold text-lg px-2">
              Save / Print Allotment
            </span>
          </div>
        </div>
        {loading ? (
          <div id="printablediv" className="bg-white py-10 hindi px-20 text-lg">
            <div className="flex flex-col text-center  ">
              <span className="font-semibold text-xl">
                कानपुर विकास प्राधिकरण, कानपुर
              </span>
              <span className="text-lg mt-2 font-semibold">
                मोती झील, कानपुर
              </span>
            </div>
            <div className="flex justify-between mt-8">
              <span>पत्रांक / / का०विग्प्रा० / 20....-20....</span>
              <span> दिनांक</span>
            </div>
            <div className="text-center underline text-lg font-semibold mt-4">
              आवंटन पत्र
            </div>
            <div className="mt-5">
              <span className="font-semibold">Subject:</span>
              <span className="ml-4">
                Regarding Allotment of a Property in JAHNVI ENCLAVE Scheme
              </span>
            </div>
            <div>
              <span className="font-semibold">विषय:</span>
              <span className="ml-4">संपत्ति आवंटन के संबंध में</span>
            </div>
            <div className="font-semibold">
              <span>Mode of Allotment :</span>
              <span className="ml-4">Counter Sale/Auction</span>
            </div>
            <div className="mt-4">सेवा में,</div>
            <div className="ml-12 mt-2">
              <div>
                <span>श्री/सुश्री / श्रीमती </span>
                <span className=" font-semibold">
                  {applicantDetails?.name
                    ? applicantDetails?.name
                    : "Rishabh Pandey"}
                </span>
              </div>
              <div>
                <span>पिता / पति </span>
                <span className=" font-semibold">
                  {applicantDetails?.father_husband
                    ? applicantDetails?.father_husband
                    : "I.P Pandey"}
                </span>
              </div>
              <div className="font-semibold">
                {applicantDetails?.current_address}
              </div>
              <div className="font-semibold">
                {applicantDetails?.current_pin}
              </div>
            </div>
            <div className="mt-4">महोदय/महोदया,</div>
            <div className="ml-12 mt-2">
              With reference to above cited subject, I have been directed to
              inform you that a Property has been alloted under and yoir
              particular are given
            </div>

            <div className="grid grid-cols-2 mt-2">
              <div className="grid grid-cols-2">
                <span className="font-semibold">Property ID:</span>
                <span>
                  {propertyDetails?.property_id
                    ? propertyDetails?.property_id
                    : "334"}
                </span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Property No.:</span>
                <span>
                  {propertyDetails?.property_no
                    ? propertyDetails?.property_no
                    : "40"}
                </span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Category:</span>
                <span>
                  {propertyDetails?.cat ? propertyDetails?.cat : "General"}
                </span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Property Code:</span>
                <span>221124012</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Scheme:</span>
                <span>BHAGIRATHI & JAHNVI ENCLAVE</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Floor:</span>
                <span>N/A</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Sector:</span>
                <span>A</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Sub Scheme:</span>
                <span>TYPE-C</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Property Type:</span>
                <span>Residential</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Section:</span>
                <span>Plot</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Registration Amount:</span>
                <span>115000.00</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Building Type:</span>
                <span>NA</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Area:</span>
                <span>50 SQ.M</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Property Cost:</span>
                <span>1500000.00</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Handicapped:</span>
                <span>N</span>
              </div>
              <div className="grid grid-cols-2">
                <span className="font-semibold">Senior Citizen:</span>
                <span>N</span>
              </div>
            </div>
            <div className="font-semibold mt-2">Payment Terms</div>
            <table className="border-collapse  w-full text-center">
              <thead>
                <tr className="font-bold">
                  <th className="border-2 border-gray-600">क्रम सख्या</th>
                  <th className="border-2 border-gray-600 ">देय का प्रकार</th>
                  <th className="border-2 border-gray-600 ">धनराशि (रू)</th>
                  <th className="border-2 border-gray-600 ">
                    प्रथम किश्त की तिथि
                  </th>
                  <th className="border-2 border-gray-600 ">
                    किश्तो की संख्या
                  </th>
                  <th className="border-2 border-gray-600 ">ब्याज़ दर</th>
                  <th className="border-2 border-gray-600 ">दंडब्याज़</th>
                </tr>
              </thead>
              <tbody>
                {PaymentTerms.map((payment) => {
                  return (
                    <tr key={payment.id}>
                      <td className="border-2 border-gray-600">{payment.id}</td>
                      <td className="border-2 border-gray-600">
                        {payment.type}
                      </td>
                      <td className="border-2 border-gray-600">{payment.rs}</td>
                      <td className="border-2 border-gray-600">
                        {payment.date}
                      </td>
                      <td className="border-2 border-gray-600">
                        {payment.terms}
                      </td>
                      <td className="border-2 border-gray-600">
                        {payment.pay}
                      </td>
                      <td className="border-2 border-gray-600">
                        {payment.intrest}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="font-semibold mt-4">
              Installments Payment Schedule
            </div>
            <div>
              आपको अवशेष शेष धन निम्न विवरण के अनुसार निर्धारित तिथि के अंदर
              रेजिस्ट्रेशन/ प्रॉपर्टी आई डी के विवरण सहित प्राधिकरण/ सम्बंधित
              बैंक क्रठिंटर में जमा करना आवश्यक होगा
            </div>
            <table className="border-collapse mt-2 w-full text-center">
              <thead>
                <tr className="font-bold">
                  <th className="border-2 border-gray-600">किश्त संख्या</th>
                  <th className="border-2 border-gray-600 ">
                    भुगतान हेतु तिथि
                  </th>
                  <th className="border-2 border-gray-600 ">मूल धनरशि</th>
                  <th className="border-2 border-gray-600 ">ब्याज़ की धनरशि</th>
                  <th className="border-2 border-gray-600 ">कुल धनरशि</th>
                </tr>
              </thead>
              <tbody>
                {(
                  data?.emi_data_second_table || InstallmentsPaymentSchedule
                ).map((item) => {
                  return (
                    <tr key={item.month_no}>
                      <td className="border-2 border-gray-600">
                        {item.month_no}
                      </td>
                      <td className="border-2 border-gray-600 font-semibold">
                        31-12-2022
                      </td>
                      <td className="border-2 border-gray-600">{item.emi}</td>
                      <td className="border-2 border-gray-600">
                        {item.inerest}
                      </td>
                      <td className="border-2 border-gray-600">
                        {item.principal.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="border-2 border-gray-600"></td>
                  <td className="border-2 border-gray-600 font-semibold">
                    कुल
                  </td>
                  <td className="border-2 border-gray-600">1385026</td>
                  <td className="border-2 border-gray-600">459403</td>
                  <td className="border-2 border-gray-600">184429</td>
                </tr>
              </tbody>
            </table>
            <div className="font-semibold my-2">नियम व शर्तें:</div>
            {Rules.map((rule) => {
              return (
                <div key={rule.id} className="">
                  <span className="font-semibold">{rule.id}</span>
                  <span className="ml-2">{rule.rule}</span>
                </div>
              );
            })}
            <div className="font-semibold underline text-center mt-2">
              किसी भी विवाद में उपाध्यक्ष कानपुर विकास प्राधिकरण का निर्णय अंतिम
              व मान्य होगा
            </div>
            <div className="font-semibold  text-end mt-5">भवदीय,</div>
            <div className="font-semibold  text-end mt-2 mb-5">
              प्रभारी Zone 4
            </div>
          </div>
        ) : (
          <div className="flex justify-center w-full">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default AavedanPatra;
