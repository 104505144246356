/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { BsFillFolderFill } from "react-icons/bs";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const cookies = new Cookies();

const Reports = () => {
  const navigate = useNavigate();
  const propertyReport = [
    {
      name: "Property Details",
      link: "propertyDetails",
    },
    {
      name: "Payment Details",
      link: "paymentDetails",
    },
    {
      name: "Data Feeding Status",
      link: "dataFeedingStatus",
    },
    {
      name: "Status Summary",
      link: "statusSummary",
    },
    {
      name: "Scheme wise Total Collection",
      link: "schemeWiseTotalCollection",
    },
    {
      name: "User Action Details",
      link: "userActionDetails",
    },
    {
      name: "Zone wise Property Record",
      link: "zoneWisePropertyRecord",
    },
    {
      name: "Property Demand wise Report",
      link: "propertyDemandWiseReport",
    },
    {
      name: "DCR Report",
      link: "dcrReport",
    },
    {
      name: "Notice",
      link: "notice",
    },
    {
      name: "Registry Report",
      link: "registryReport",
    },
    {
      name: "Dues Report",
      link: "duesReport",
    },
  ];
  const netPaymentReport = [
    {
      name: "Net Payment Due Report",
      link: "netPaymentDueReport",
    },
    {
      name: "Net Paymnet Collection(Bank)test Report",
      link: "netPaymnetCollectionReport",
    },
    {
      name: "Net Payment Report Daily Collection",
      link: "netPaymentReportDailyCollection",
    },
  ];
  const allotmentReport = [
    {
      name: "Generate Allotment Letter",
      link: "generateAllotmentLetter",
    },
    {
      name: "Generate Provisional Allotment Letter",
      link: "generateProvisionalAllotmentLetter",
    },
  ];
  const propertyDefaulter = [
    {
      name: "Defaulters List",
      link: "defaultersList",
    },
    {
      name: "Property Defaulter (Last Three Int.)",
      link: "propertyDefaulter",
    },
    {
      name: "Property Defaulter Top10 (Last Three Int.)",
      link: "propertyDefaulterTop10",
    },
    {
      name: "Defaulter Summary (last Three Int.)",
      link: "defaulterSummary",
    },
  ];

  return (
    <>
      <div className="bg-default-background pb-10 px-3 md:px-5 w-full h-screen ">
        <div
          style={{ marginBottom: "20px" }}
          className="bg-white rounded-md px-3 md:px-5 py-4 "
        >
          <div className="flex">
            <BsFillFolderFill className="text-xl mt-1" />
            <div className="font-semibold md:text-lg ml-2">Property Report</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-5 mt-3 ">
            {propertyReport.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">
              Net Payment Report
            </div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {netPaymentReport.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">
              Allotment Report
            </div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {allotmentReport.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">
              Property Defaulter
            </div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {propertyDefaulter.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
