import { configureStore } from '@reduxjs/toolkit';
import HeaderReducer from '../features/HeaderSlice'
import InfoReducer from '../features/InfoSlice'
import UserReducer from '../features/UserSlice'
import HeadReducer from '../features/HeadSlice'

export const store = configureStore({
  reducer: {
    header: HeaderReducer,
    info: InfoReducer,
    user: UserReducer,
    head: HeadReducer
  },
});
