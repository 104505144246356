import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, DatePicker, Badge, Card, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import SideBarTest from "../../../components/SideBarTest";
import { notify } from "../../../App";
import Spinner from "../../Spinner/Spinner";
import moment from "moment";

const { RangePicker } = DatePicker;
const cookies = new Cookies();

const SchemeList = () => {
  const navigate = useNavigate();
  const [getAllSchemes, setGetAllSchemes] = useState([]);
  const getAllData = () => {
    axios
      .get(`property/scheme-list`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setGetAllSchemes(res.data);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <SideBarTest>
      {getAllSchemes?.length !== 0 ? (
        <div className="  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb className="text-lg">
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className=" font-semibold ">
                All Scheme
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className=" flex mx-10 justify-end">
            <div
              onClick={() => navigate("createScheme")}
              className="bg-yellow-100 cursor-pointer md:px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
            >
              <span className="text-black font-semibold text-lg px-2">
                Create Scheme
              </span>
            </div>
          </div>
          <div className="bg-white  md:mx-10 px-6  py-4 mt-3 grid lg:grid-cols-2 gap-5  rounded-md">
            {getAllSchemes?.map((item) => {
              return (
                <div key={item.id}>
                  <Badge.Ribbon
                    color={item.progress_status === "Completed" ? "green" : ""}
                    text={item.progress_status}
                  >
                    <div
                      className={
                        "bg-yellow-100 border rounded-md shadow-md  border-gray-200 p-4"
                      }
                    >
                      <div className="space-y-2">
                        <div className="text-lg font-semibold">
                          {item?.name}
                        </div>
                        <div>
                          Start Date :{" "}
                          {moment(item.start_date).format("MMM Do YY")}
                        </div>
                        <div className="flex justify-between">
                          <div>
                            Completion Date :{" "}
                            {moment(item.completion_date).format("MMM Do YY")}
                          </div>
                          <div>Zone : {item.zone}</div>
                        </div>
                        <div className="flex justify-center mt-4">
                          <div
                            onClick={() => navigate(`/scheme/${item.id}`)}
                            className="bg-default_yellow cursor-pointer px-4 py-2 shadow-md  w-auto text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                          >
                            <span className="text-black font-semibold text-lg px-2">
                              View Details
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Badge.Ribbon>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-full">
          <Spinner />
        </div>
      )}
    </SideBarTest>
  );
};

export default SchemeList;
