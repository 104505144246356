import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { InboxOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { MdCancel } from "react-icons/md";
import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
  Upload,
  message,
} from "antd";
import SideBarTest from "../../../components/SideBarTest";
import moment from "moment";
import { notify } from "../../../App";

const { RangePicker } = DatePicker;
const cookies = new Cookies();

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateScheme = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const formData = new FormData();

  const changeHandle = (key, value) => {
    setData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
    // formData.append(key, value);
  };

  const onDrop = useCallback((acceptedFiles) => {
    getBase64(acceptedFiles[0], (url) => {
      setImageUrl(url);
    });
    changeHandle("map", acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "text/html": [".html", ".htm"],
    },
  });
  const submit = (item) => {
    Object.entries(item).forEach(([key, value]) => {
      console.log(key, value, "here");
      formData.append(key, value);
    });

    axios
      .post(`property/scheme-create`, formData, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        navigate("/scheme");
        notify({ message: "New Scheme Created !!", type: "success" });
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  return (
    <>
      <SideBarTest>
        <div className=" h-screen  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb className="text-lg">
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/scheme")}
              >
                Schemes
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Create Scheme
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className=" bg-light_yellow  py-3 px-5  mt-4 font-semibold text-lg rounded-t-md">
              Create Scheme
            </div>
            <div className="bg-white   px-10 py-4  rounded-b-md">
              <div className="text-lg font-semibold">Scheme Details</div>
              <div className="grid lg:grid-cols-2 xl:grid-cols-3  gap-x-10 gap-y-5 mt-4">
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Scheme Name</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) => changeHandle("name", val.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Zone</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) => changeHandle("zone", val.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Ward No.</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) => changeHandle("ward", val.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Estimate Cost</div>
                  <div>
                    <Input
                      type="number"
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("estimate_cost", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Work Details</div>
                  <div>
                    <Select
                      defaultValue="Select Work Details"
                      onChange={(val) => changeHandle("work_details", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "Construction",
                          label: "Construction",
                        },
                        {
                          value: "Underconstruction",
                          label: "Under construction",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Start Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "start_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Completion Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "completion_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Sanction Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "sanction_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Tender Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "tender_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Progress Status</div>
                  <div>
                    <Select
                      defaultValue="Select Status"
                      onChange={(val) => changeHandle("progress_status", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "In-Process",
                          label: "In-Process",
                        },
                        {
                          value: "Draft",
                          label: "Draft",
                        },
                        {
                          value: "Completed",
                          label: "Completed",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-4">
                <div className="text-lg">Scheme Image</div>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  {imageUrl ? (
                    <p className="mt-4 h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                      <img src={imageUrl} className="h-40 " />
                    </p>
                  ) : (
                    <p className="mt-4 h-32 bg-gray-100 rounded-md cursor-pointer border-dashed border-2 border-gray-300 flex justify-center items-center">
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex mt-10  space-x-4">
                <div
                  onClick={() => submit(data)}
                  className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                >
                  <span className="text-black font-semibold text-lg px-2">
                    Submit
                  </span>
                </div>
                <div
                  onClick={() => navigate("/scheme")}
                  className="bg-gray-100 flex cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-800 text-gray-600 hover:bg-gray-200"
                >
                  <span>
                    <MdCancel className=" font-semibold text-lg mt-1.5" />
                  </span>
                  <span className="  font-semibold text-lg px-2">Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBarTest>
    </>
  );
};

export default CreateScheme;
