import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  DatePicker,
  Badge,
  Space,
  Input,
  Select,
  Drawer,
  Table,
  Button,
  Dropdown,
  Menu,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import SideBarTest from "../../../components/SideBarTest";
import { notify } from "../../../App";
import moment from "moment";
import Spinner from "../../Spinner/Spinner";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { RedoRounded } from "@mui/icons-material";

const { RangePicker } = DatePicker;
const cookies = new Cookies();

const Statusdata = [
  {
    name: "Select",
    value: true,
  },
  {
    name: "Reject",
    value: false,
  },
];

const ViewApplicant = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [application, setApplication] = useState();
  const id = window.location.pathname.slice(15);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getDetails = () => {
    axios
      .get(`property/user-application-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setApplication(res.data);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  const statusHandle = (item) => {
    axios
      .get(`property/selection-status-change/${item}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        notify({ message: "Status Updated !", type: "success" });
        getDetails();
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Advertisement ID",
      dataIndex: "advertisement_unique_id",
      key: "advertisement_unique_id",
      ...getColumnSearchProps("advertisement_unique_id"),
    },
    {
      title: "Aadhaar Card No.",
      dataIndex: "aadhaar",
      key: "aadhaar",
      render: (record) => {
        return <div className="flex ">{record === null ? "N/A" : record}</div>;
      },
    },
    {
      title: "Date Of Application",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
      render: (record) => {
        return (
          <div className="flex ">
            {moment(record.created_at).format("MMM Do YY")}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "selection",
      filterMode: "tree",
      className: "style-normalizer",

      render: (record) => {
        return (
          <div className="flex gap-4 items-center">
            <Dropdown.Button overlay={statusMenu(record)} trigger={["click"]}>
              {record.selection === true ? "Select" : "Reject"}
            </Dropdown.Button>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => {
        return (
          <div className="flex gap-4 lg:gap-4  justify-start">
            <Button
              onClick={() =>
                navigate(
                  `/viewApplicantDetails?application_id=${record.id}&advertisement_Id=${id}&scheme=${record.scheme}`
                )
              }
            >
              View Details
            </Button>
          </div>
        );
      },
    },
  ];
  const statusMenu = (record) => (
    <Menu>
      {Statusdata.map((val, i) => {
        return (
          // <Menu.Item onClick={() => statusHandle(record, val)} key={i}>
          <Menu.Item onClick={() => statusHandle(record.id)} key={i}>
            {val.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <SideBarTest>
      {!loading ? (
        <div className="flex justify-center w-full">
          <Spinner />
        </div>
      ) : (
        <div className="">
          <div className=" md:px-10 py-4">
            <Breadcrumb className="text-lg">
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/advertisementList")}
              >
                Advertisement List
              </Breadcrumb.Item>
              <Breadcrumb.Item className=" font-semibold ">
                View Applicant
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="mx-4">
            <Table columns={columns} dataSource={application} />
          </div>
        </div>
      )}
    </SideBarTest>
  );
};
export default ViewApplicant;
