import React, { useEffect, useState } from "react";
import { selectHead } from "../../features/HeadSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import comp from "./profile.jpeg";
import { FaRegEdit } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import { RiCheckDoubleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Button, Card, CardContent } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import image1 from "../Team Manage/3135715.png";

const cookies = new Cookies();

const EditProfile = () => {
  const navigate = useNavigate();

  const currentState = useSelector(selectHead);

  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [empId, setEmpId] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  const [otp, setOtp] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [country, setCountry] = useState("");
  const [add, setAdd] = useState("");
  const [github_id, setGithub_id] = useState("");
  const [linkedin_id, setLinkedin_id] = useState("");

  async function updateImage(e) {
    e.preventDefault();

    console.log(e.target.files[0]);

    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    console.log(formData);

    axios
      .put(`auth/user/profile/update/${id}`, formData, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setImage(formData);
        cookies.set("image", image);
        console.log(image);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function updateData(e) {
    e.preventDefault();

    axios
      .put(
        `auth/user/profile/update/${id}`,
        {
          name: name,
          email: email,
          otp: otp,
          gender: gender,
          otp_phone: phone,
          role: role,
          employee_id: empId,
          dob: dob,
          about_you: about,
          city: city,
          state: state,
          pin: pin,
          address: add,
          github_id: github_id,
          linkedin_id: linkedin_id,
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        navigate("/profile");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getData() {
    await axios
      .get("auth/user/profile", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        console.log(resp.data);
        setEmail(resp.data.user.email);
        setId(resp.data.user.id);
        setName(resp.data.user.profile.name);
        setPhone(resp.data.user.profile.otp_phone);
        setDob(resp.data.user.profile.dob);
        setEmpId(resp.data.user.profile.employee_id);
        setRole(resp.data.user.profile.role);
        setGender(resp.data.user.profile.gender);
        setAbout(resp.data.user.profile.about_you);
        setImage(resp.data.user.profile.image);
        setCity(resp.data.user.profile.city);
        setState(resp.data.user.profile.state);
        setPin(resp.data.user.profile.pin);
        setAdd(resp.data.user.profile.address);
        setGithub_id(resp.data.user.profile.github_id);
        setLinkedin_id(resp.data.user.profile.linkedin_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (cookies.get("token")) {
      setOtp(cookies.get("otp"));
      setId(cookies.get("id"));
      getData();
    } else {
      navigate("/");
    }
  }, [image]);

  return (
    <div className="bg-[#EDEBEE] h-screen overflow-auto">
      <Header />
      <Sidebar />
      <div className="pt-4 pb-20 px-4  md:px-10   w-full">
        <Card elevation={0}>
          <CardContent>
            <div className="flex flex-row mb-4">
              <p className="font-semibold text-sky-700 pt-2 pl-4 flex-grow text-xl md:text-xl lg:text-2xl">
                My Profile
              </p>
              <div>
                <Button
                  align="right"
                  className="sm:w-4 md:w-10 lg:w-20"
                  onClick={updateData}
                  href={"profile"}
                  style={{
                    backgroundColor: "#F1F1F1",
                    borderRadius: "10px",
                    color: "rgb(8, 101, 182)",
                  }}
                >
                  Save <EditIcon style={{ fontSize: "medium" }} />
                </Button>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
              <div
                className=" md:col-span-3 lg:col-span-1 relative"
                align="center"
              >
                <div className="w-20 border-gray-600 cursor-pointer rounded-full">
                  <img
                    style={{ filter: "blur(1.2px)" }}
                    className="w-20 h-20  rounded-full "
                    src={image ? image : image1}
                    alt=""
                  />
                  <label className="">
                    <FaRegEdit className="text-xs  absolute mt-[-45px] ml-8 text-lg xl:text-lg 2xl:text-2xl md:text-base  cursor-pointer" />
                    <input
                      className="w-20"
                      type="file"
                      onChange={updateImage}
                      style={{ visibility: "hidden" }}
                    />
                  </label>
                </div>
              </div>
              <div className="col-span-3">
                <div>
                  <TextField
                    fullWidth
                    label="Name"
                    value={name ? name : ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="grid col-span-1 md:grid-cols-2 gap-4 mt-4">
                  <div>
                    <TextField
                      fullWidth
                      label="Email ID"
                      value={email ? email : ""}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  {/*<div>*/}
                  {/*    <TextField*/}
                  {/*        fullWidth*/}
                  {/*        label="Current Status"*/}
                  {/*        value={status}*/}
                  {/*        onChange={(e) => setStatus(e.target.value)}*/}
                  {/*    />*/}
                  {/*</div>*/}

                  <div>
                    <TextField
                      fullWidth
                      label="Contact"
                      value={phone ? phone : ""}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="Employee ID"
                      value={empId ? empId : ""}
                      onChange={(e) => setEmpId(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="Date Of Birth"
                      value={dob ? dob : ""}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="Designation"
                      value={role ? role : ""}
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="Gender"
                      value={gender ? gender : ""}
                      onChange={(e) => setGender(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="City"
                      value={city ? city : ""}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label="State"
                      value={state ? state : ""}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="Pin"
                      value={pin ? pin : ""}
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label="Address"
                      value={add ? add : ""}
                      onChange={(e) => setAdd(e.target.value)}
                    />
                  </div>

                  <div className="col-span-1 md:col-span-2">
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={4}
                      label="About You"
                      value={about ? about : ""}
                      onChange={(e) => setAbout(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="Github"
                      value={github_id ? github_id : ""}
                      onChange={(e) => setGithub_id(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="LinkedIn"
                      value={linkedin_id ? linkedin_id : ""}
                      onChange={(e) => setLinkedin_id(e.target.value)}
                    />
                  </div>
                  {/*<div>*/}
                  {/*    <TextField*/}
                  {/*        fullWidth*/}
                  {/*        label="Joined in Company"*/}
                  {/*        value={joinedDate ? joinedDate : ""}*/}
                  {/*        onChange={(e) => setJoinedDate(e.target.value)}*/}
                  {/*    />*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/*<div className="bg-white px-4 md:px-10 pb-20 pt-8 w-full rounded-lg h-auto">*/}
        {/*    <div className='md:flex justify-between'>*/}
        {/*        <div className='md:w-80'>*/}
        {/*            <div className="flex justify-center ">*/}
        {/*<div className="w-20  border-gray-600 cursor-pointer rounded-full">*/}
        {/*    <img*/}
        {/*        style={{filter: "blur(1.2px)"}}*/}
        {/*        className="w-20 h-20  rounded-full "*/}
        {/*        src={image ? image : comp}*/}
        {/*        alt=""*/}
        {/*    />*/}
        {/*    <label className=''>*/}
        {/*        <FaRegEdit*/}
        {/*            className="text-xs  absolute mt-[-45px] ml-8 text-lg xl:text-lg 2xl:text-2xl md:text-base  cursor-pointer"/>*/}
        {/*        <input className='w-20' type="file" onChange={updateImage}*/}
        {/*               style={{visibility: "hidden"}}/>*/}
        {/*    </label>*/}
        {/*</div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className=' w-full mx-10  ml-[-10px]'>*/}
        {/*            <div className='flex space-x-10 xl:space-x-20'>*/}
        {/*                <div className='space-y-4'>*/}
        {/*                    <div>*/}
        {/*                        <div className="">*/}

        {/*                            <div className='text-gray-400 font-semibold'>Full Name</div>*/}

        {/*                            <TextField*/}
        {/*                                className={*/}
        {/*                                    !currentState?.show*/}
        {/*                                        ? "w-full  "*/}
        {/*                                        : "w-full xl:w-80 "*/}
        {/*                                }*/}
        {/*                                value={name}*/}
        {/*                                id="outlined-size-small"*/}
        {/*                                onChange={(e) => setName(e.target.value)}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    <div>*/}
        {/*                        <div className="">*/}
        {/*                            <div className='text-gray-400 font-semibold'>Email ID</div>*/}
        {/*                            <TextField*/}
        {/*                                className="w-full "*/}
        {/*                                value={email}*/}
        {/*                                id="outlined-size-small"*/}
        {/*                                onChange={(e) => setEmail(e.target.value)}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    <div>*/}
        {/*                        <div className="">*/}
        {/*                            <div className='text-gray-400  font-semibold'>Date of Birth</div>*/}
        {/*                            <TextField*/}
        {/*                                className="w-full "*/}
        {/*                                value={dob}*/}
        {/*                                id="outlined-size-small"*/}
        {/*                                onChange={(e) => setDob(e.target.value)}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                        <div className=" mt-4">*/}
        {/*                            <div className='text-gray-400  font-semibold'>Contact</div>*/}
        {/*                            <TextField*/}
        {/*                                className="w-full "*/}
        {/*                                value={phone}*/}
        {/*                                id="outlined-size-small"*/}
        {/*                                onChange={(e) => setPhone(e.target.value)}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className='space-y-4'>*/}
        {/*                    <div>*/}
        {/*                        <div className="">*/}
        {/*                            <div className='text-gray-400 font-semibold'>Employee ID</div>*/}
        {/*                            <TextField*/}
        {/*                                className={*/}
        {/*                                    !currentState?.show*/}
        {/*                                        ? "w-full  "*/}
        {/*                                        : "w-full xl:w-80 "*/}
        {/*                                }*/}
        {/*                                value={empId}*/}
        {/*                                id="outlined-size-small"*/}
        {/*                                onChange={(e) => setEmpId(e.target.value)}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    <div>*/}
        {/*                        <div className="">*/}
        {/*                            <div className='text-gray-400 font-semibold'>Designation</div>*/}
        {/*                            <TextField*/}
        {/*                                className="w-full "*/}
        {/*                                value={role}*/}
        {/*                                id="outlined-size-small"*/}
        {/*                                onChange={(e) => setRole(e.target.value)}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    <div>*/}
        {/*                        <div className="">*/}
        {/*                            <div className='text-gray-400 font-semibold'>Gender</div>*/}
        {/*                            <TextField*/}
        {/*                                className="w-full "*/}
        {/*                                value={gender}*/}
        {/*                                id="outlined-size-small"*/}
        {/*                                onChange={(e) => setGender(e.target.value)}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className='mt-10'>*/}
        {/*                <div className='text-gray-400 font-semibold'>About You</div>*/}
        {/*                <div className='lg:pr-20'>*/}
        {/*   <textarea*/}

        {/*       className="h-28  mt-2   2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"*/}
        {/*       placeholder="Discribe anything in message"*/}
        {/*       cols="10"*/}
        {/*       rows="5"*/}
        {/*       value={about ? about : "Discrobe about yourself"}*/}
        {/*       onChange={(e) => setAbout(e.target.value)}*/}
        {/*   />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className=''>*/}
        {/*            <Link to="/Profile">*/}
        {/*                <div onClick={updateData}*/}
        {/*                     className=' mt-5 md:mt-0 w-full px-10  h-10 flex justify-center  bg-slate-100 rounded-lg'>*/}
        {/*                    <div className='  flex bg-slate-100 my-2 text-blue-700 '>*/}
        {/*                        <div>Save</div>*/}
        {/*                        <RiCheckDoubleFill className='ml-2 mt-[1px] text-xl'/></div>*/}
        {/*                </div>*/}
        {/*            </Link>*/}
        {/*        </div>*/}
        {/*    </div>*/}

        {/*</div>*/}
      </div>
    </div>
  );
};

export default EditProfile;
