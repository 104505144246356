import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Settings from "./Pages/Settings/Settings";
import Profile from "./Pages/Profile/Profile";
import EditProfile from "./Pages/Profile/EditProfile";
import HelpAndFAQ from "./Pages/Help and FAQ/HelpAndFAQ";
import Help from "./Pages/Help and FAQ/Help";
import Contact from "./Pages/Home/Contact";
import Manage from "./Pages/Manage";
import ViewProperty from "./Pages/ManageProperty/ViewProperty";
import NewProperty from "./Pages/ManageProperty/NewProperty";
import PropertyTransfer from "./Pages/ManageProperty/PropertyTransfer";
import PropertyStatusManager from "./Pages/ManageProperty/PropertyStatusManager";
import SchemeList from "./Pages/Schemes/SchemeList/SchemeList";
import AdvertisementList from "./Pages/Advertisement/AdvertisementList/AdvertisementList";
import CreateScheme from "./Pages/Schemes/CreateScheme/CreateScheme";
import { toast } from "react-toastify";
import SchemeDetails from "./Pages/Schemes/SchemeDetails/SchemeDetails";
import AdvertisementDetails from "./Pages/Advertisement/AdvertisementDetails";
import ApplyList from "./Pages/Apply/ApplyList";
import ApplyForm from "./Pages/Apply/ApplyForm";
import ViewApplicant from "./Pages/Apply/ViewApplicant";
import ApplicantDetails from "./Pages/Apply/ApplicantDetails";
import PropertyDetailsList from "./Pages/ManageProperty/PropertyDeatilsList/PropertyDetailsList";
import AavedanPatra from "./components/AavedanPatra";

export const notify = (data) => {
  const { type, message } = data;
  toast.dismiss();

  switch (type) {
    case "info":
      toast.info(message);
      break;
    case "success":
      toast.success(message);
      break;
    case "warning":
      toast.warn(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
      break;
  }
};

function App() {
  return (
    <div className="App z-40 bg-default-gray ">
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login/*" element={<Login />} />
          <Route path="/dashboard/*" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/help" element={<Help />} />
          <Route path="/help/*" element={<HelpAndFAQ />} />
          <Route path="/manage/*" element={<Manage />} />
          <Route path="/manage/newProperty" element={<NewProperty />} />
          <Route path="/scheme" element={<SchemeList />} />
          <Route path="/advertisementList" element={<AdvertisementList />} />
          <Route path="/scheme/createScheme" element={<CreateScheme />} />
          <Route path={`/scheme/*`} element={<SchemeDetails />} />
          <Route path={`/propertyDetails/*`} element={<ViewProperty />} />
          <Route path={`/apply`} element={<ApplyList />} />
          <Route path={`/apply/Applyform/*`} element={<ApplyForm />} />
          <Route path={`/viewApplicant/*`} element={<ViewApplicant />} />
          <Route
            path={`/propertyDetailsList/*`}
            element={<PropertyDetailsList />}
          />

          <Route
            path={`/viewApplicantDetails/*`}
            element={<ApplicantDetails />}
          />
          <Route
            path={`/advertisementList/*`}
            element={<AdvertisementDetails />}
          />
          <Route
            path="/manage/updateStatus"
            element={<PropertyStatusManager />}
          />
          <Route
            path="/manage/propertyTransfer"
            element={<PropertyTransfer />}
          />
          <Route path="/AavedanPatra/*" element={<AavedanPatra />} />
        </Routes>
        <ToastContainer autoClose={2000} limit={1} />
      </Router>
    </div>
  );
}

export default App;
