/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { hiding, selectHeader, showing } from "../features/HeaderSlice";
import { useSelector } from "react-redux";
import logo from "../Images/logo.png";
import messageIcon from "../Images/Message.png";
import "./Header.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import CircleIcon from "@mui/icons-material/Circle";
import { styled, alpha } from "@mui/material/styles";
import Cookies from "universal-cookie";
import {
  Badge,
  Dialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Drawer, Radio, Space } from "antd";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AiFillPieChart } from "react-icons/ai";
import "./Sidebar.scss";
import { signOut } from "firebase/auth";
import ProfileLogo from "../Images/user.png";

const cookies = new Cookies();

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Header = ({ username }) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const dispatch = useDispatch();
  const currentState = useSelector(selectHeader);

  const [broadcastData, setBroadcastData] = useState([]);
  const [dataLen, setDataLen] = useState(0);

  const [adminName, setAdminName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [visible1, setVisible1] = useState(false);
  const [placement, setPlacement] = useState("top");

  const handleOpen = () => {
    axios
      .get("chat/connect", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        console.log("resp", resp.data.redirect_url);
        window.open(resp.data.redirect_url, "_blank", "noopener,noreferrer");
      })
      .catch((error) => {});
  };
  const Logout = () => {
    axios
      .post(
        `auth/user/logout`,
        {
          user_id: localStorage.getItem("user"),
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Logout Successfully");
        cookies.remove("token");
        navigate("/login");
        toast.success("Successfully logged out", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log("Error: ", err);
        toast.error("Error logging out", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  const profileInfo = () => {
    axios
      .get(`auth/user/profile`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setAdminName(res.data.user.profile.name);
        setProfilePicture(res.data.user.profile.image);
      })
      .catch((err) => {
        console.log("Error loading the header profile data", err);
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const visible = () => {
    dispatch(
      showing({
        show: true,
      })
    );

    if (currentState?.show === true) {
      dispatch(hiding());
    }
  };

  useEffect(() => {
    profileInfo();
  }, []);

  const showDrawer = () => {
    setVisible1(true);
  };

  const onClose = () => {
    setVisible1(false);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  return (
    <>
      <div className="header_main sticky fixed-top top-0 shadow-md z-40 py-2">
        <div className=" flex px-1 ">
          <div className="">
            {currentState?.show === true ? (
              <IconButton>
                {" "}
                <CloseIcon onClick={showDrawer} className="cursor-pointer" />
              </IconButton>
            ) : (
              <IconButton>
                {" "}
                <MenuIcon onClick={showDrawer} className="cursor-pointer" />
              </IconButton>
            )}
          </div>

          <div className="flex   logo-container space-x-2 text-xl px-2">
            <a href="/dashboard">
              <img
                src={logo}
                alt=""
                style={{ height: 40 }}
                className="border-2 border-slate-300 p-0.5 rounded-full"
              />
            </a>
            <p className="font-semibold mt-3 self-center  hidden md:block text-sm md:text-base">
              Kanpur Development Authority
            </p>
          </div>

          <div className="flex space-x-3">
            <div className="space-x-2 flex justify-center">
              <Button
                style={{ border: "none" }}
                onClick={handleClick}
                variant="text"
                color=""
              >
                <div className="flex">
                  <img
                    src={profilePicture || ProfileLogo}
                    className="  mr-1 rounded-full w-7 h-7"
                    alt=""
                  />
                  <p className="px-2 text-black">{adminName}</p>

                  <ArrowDropDownIcon className="arrow_icon text-black" />
                </div>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate("/profile")} disableRipple>
                  <PersonIcon />
                  MyProfile
                </MenuItem>
                <MenuItem onClick={() => navigate("/settings")} disableRipple>
                  <SettingsIcon />
                  Settings
                </MenuItem>
                <MenuItem onClick={() => navigate("/help")} disableRipple>
                  <HelpIcon />
                  Help & FAQ
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={Logout} disableRipple>
                  <LogoutIcon />
                  Logout
                </MenuItem>
              </StyledMenu>

              <StyledDialog
                onClose={handleCloseDialog}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
              >
                <div>Swiper</div>
              </StyledDialog>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title={
          <div className="text-xs font-bold">Kanpur Development Authority</div>
        }
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={visible1}
        key={placement}
        width={280}
        extra={
          <Space>
            {/* <Button onClick={onClose}>Cancel</Button> */}
            <IconButton onClick={onClose}>
              <CloseIcon onClick={showDrawer} className="cursor-pointer" />
            </IconButton>
          </Space>
        }
      >
        <div className="flex flex-col justify-between h-full">
          <div className="space-y-2">
            <div
              onClick={() => navigate("/dashboard")}
              className={`hover:bg-light_yellow rounded flex text-neutral-400 items-center cursor-pointer hover:text-gray-700 p-2 font-bold 
                            ${
                              window.location.pathname === "/dashboard"
                                ? "bg-light_yellow text-gray-700"
                                : ""
                            }`}
            >
              <p className="m-1 flex">
                <AiFillPieChart className="mr-3 text-2xl" />
                Dashboard
              </p>
            </div>

            <div className="flex space-x-3">
              <div className="space-x-2 flex justify-center">
                <div
                  onClick={() => {
                    navigate("/manage/manageProperty");
                    handleClick();
                  }}
                  className={`hover:light_yellow rounded flex text-neutral-400 items-center cursor-pointer hover:text-gray-700 p-2 font-bold 
                            ${
                              window.location.pathname.includes("/manage/")
                                ? "bg-light_yellow text-gray-700"
                                : ""
                            }`}
                >
                  <p className="m-1 flex">
                    <DashboardIcon className="mr-3" />
                    <div>Manage</div>
                  </p>
                </div>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => navigate("/profile")} disableRipple>
                    <PersonIcon />
                    MyProfile
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/settings")} disableRipple>
                    <SettingsIcon />
                    Settings
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/help")} disableRipple>
                    <HelpIcon />
                    Help & FAQ
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={Logout} disableRipple>
                    <LogoutIcon />
                    Logout
                  </MenuItem>
                </StyledMenu>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              const token = cookies.get("token");
              cookies.remove("token");
              signOut(auth)
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            className={`hover:bg-light_yellow rounded flex text-neutral-400 items-center cursor-pointer hover:text-gray-700 p-2 font-bold`}
          >
            <p className="m-1">
              <LogoutIcon className="mr-5" />
              Logout
            </p>
          </div>
        </div>
      </Drawer>
      <div
        className="z-10 bg-blue-200 w-16 h-16 p-[10px] rounded-full flex items-center justify-center absolute bottom-10 right-10 hover:cursor-pointer hover:opacity-70 active:opacity-70 duration-300"
        onClick={handleOpen}
      >
        <span>
          <img src={messageIcon} />
        </span>
      </div>
    </>
  );
};

export default Header;
