import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Cookies from "universal-cookie";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
} from "antd";
import SideBarTest from "../../../components/SideBarTest";
import { notify } from "../../../App";
import { MdCancel } from "react-icons/md";
import moment from "moment";

const { RangePicker } = DatePicker;
const cookies = new Cookies();

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const NewProperty = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [getAllSchemes, setGetAllSchemes] = useState([]);
  const formData = new FormData();

  const changeHandle = (key, value) => {
    setData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
    // formData.append(key, value);
  };

  const onDrop = useCallback((acceptedFiles) => {
    getBase64(acceptedFiles[0], (url) => {
      setImageUrl(url);
    });
    changeHandle("image", acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "text/html": [".html", ".htm"],
    },
  });
  const getAllData = () => {
    axios
      .get(`property/scheme-list`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setGetAllSchemes(res.data);
      })
      .catch((err) => {
        notify({ message: err.message, type: "error" });
      });
  };
  const submit = (item) => {
    Object.entries(item).forEach(([key, value]) => {
      console.log(key, value, "here");
      formData.append(key, value);
    });

    axios
      .post(`property/property-create`, formData, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        navigate("/manage/manageProperty");
        notify({ message: "New Property Created !!", type: "success" });
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <SideBarTest>
        <div className=" h-screen  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb className="text-lg">
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer"
                onClick={() => navigate("/manage/manageProperty")}
              >
                Property Master
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Define New Property
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className=" bg-light_yellow  py-3 px-5  mt-4 font-semibold text-lg rounded-t-md">
              Define New Property
            </div>
            <div className="bg-white   px-10 py-4  rounded-b-md">
              <div className="text-lg font-semibold">Section Name</div>
              <div className="grid lg:grid-cols-2 xl:grid-cols-3  gap-x-10 gap-y-5 mt-4">
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Scheme *</div>
                  <div>
                    <Select
                      defaultValue="Select Scheme"
                      onChange={(val) => changeHandle("scheme", val)}
                      className="rounded-md"
                      options={getAllSchemes?.map((item) => {
                        return {
                          value: item.id,
                          label: item?.name,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Sub Scheme</div>
                  <div>
                    <Select
                      defaultValue="Select Sub-Scheme"
                      onChange={(val) => changeHandle("sub_scheme", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "Phase1",
                          label: "Phase 1",
                        },
                        {
                          value: "Phase2",
                          label: "Phase 2",
                        },

                        {
                          value: "Phase3",
                          label: "Phase 3",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Select Draw Details</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("draw_detail", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">SCH-CATCD *</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("sch_cat_cd", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Property ID</div>
                  <div>
                    <Input
                      placeholder="ID"
                      onChange={(val) =>
                        changeHandle("property_no", val.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <div className="text-lg">Property No.*</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("property_no", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Estimate Cost</div>
                  <div>
                    <Input
                      placeholder="xxx.xx"
                      type="number"
                      onChange={(val) =>
                        changeHandle("estimate_cost", val.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Quota Code</div>
                  <div>
                    <Select
                      defaultValue="Select Quota Code"
                      onChange={(val) => changeHandle("quota_code", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "General",
                          label: "General",
                        },
                        {
                          value: "SC",
                          label: "SC",
                        },
                        {
                          value: "ST",
                          label: "ST",
                        },
                        {
                          value: "OBC",
                          label: "OBC",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Section *</div>
                  <div>
                    <Select
                      defaultValue="Select Section"
                      onChange={(val) => changeHandle("section", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "Commercial",
                          label: "Commercial",
                        },
                        {
                          value: "Educational",
                          label: "Educational",
                        },
                        {
                          value: "Agriculture",
                          label: "Agriculture",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="flex ">
                  <Checkbox
                    onChange={(e) =>
                      changeHandle("handicaped", e.target.checked)
                    }
                  >
                    <span className="font-semibold ">Handlicapped</span>
                  </Checkbox>
                  <Checkbox
                    onChange={(e) =>
                      changeHandle("sr_citizen ", e.target.checked)
                    }
                  >
                    <span className="font-semibold ">Senior Citizen</span>
                  </Checkbox>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Property Type *</div>
                  <div>
                    <Select
                      defaultValue="Select Property Type"
                      onChange={(val) => changeHandle("section", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "Plot",
                          label: "Plot",
                        },
                        {
                          value: "Flat",
                          label: "Flat",
                        },
                        {
                          value: "EWS",
                          label: "EWS",
                        },
                        {
                          value: "MIG",
                          label: "MIG",
                        },
                        {
                          value: "LIG",
                          label: "LIG",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <div className="text-lg">Building Type *</div>
                  <div className="flex justify-between">
                    <Select
                      defaultValue="Select Building Type"
                      onChange={(val) => changeHandle("building_type", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "Ground_Floor",
                          label: "Ground Floor",
                        },
                        {
                          value: "First_floor",
                          label: "First floor",
                        },
                        {
                          value: "School",
                          label: "School",
                        },
                        {
                          value: "Hospital",
                          label: "Hospital",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Est.Plot Size (covered)</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("final_plot_size", val.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-2">
                <div className="text-lg">Property Image</div>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  {imageUrl ? (
                    <p className="mt-4 h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                      <img src={imageUrl} className="h-40 " />
                    </p>
                  ) : (
                    <p className="mt-4 h-32 bg-gray-100 rounded-md cursor-pointer border-dashed border-2 border-gray-300 flex justify-center items-center">
                      Drag 'n' drop Property Image, or click to select image
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex mt-10  space-x-4">
                <div
                  onClick={() => submit(data)}
                  className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                >
                  <span className="text-black font-semibold text-lg px-2">
                    Submit
                  </span>
                </div>
                <div
                  onClick={() => navigate("/manage/manageProperty")}
                  className="bg-gray-100 flex cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-800 text-gray-600 hover:bg-gray-200"
                >
                  <span>
                    <MdCancel className=" font-semibold text-lg mt-1.5" />
                  </span>
                  <span className="  font-semibold text-lg px-2">Cancel</span>
                </div>
              </div>
            </div>
            {/* <div className="rounded-md px-5 py-4 bg-white mt-4">
              <div className="text-lg font-semibold">Personal Details</div>
              <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-5 mt-4">
                <div className="grid grid-cols-2">
                  <div className="text-lg">Bank</div>
                  <div>
                    <Select
                      defaultValue="Select Bank"
                      onChange={(val) => changeHandle("bank", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "Axis",
                          label: "Axis",
                        },
                        {
                          value: "ICICI",
                          label: "ICICI",
                        },
                        {
                          value: "Indian_Bank",
                          label: "Indian Bank",
                        },
                        {
                          value: "PNB",
                          label: "PNB",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Reg. Amount *</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("reg_amount", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Registration Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "registry_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Current Allotee</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("current_allotee", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Father/Husband Name</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle(
                          "current_allotee_father_husband",
                          val.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Possession Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "possesion_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Address</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle(
                          "current_allotee_address",
                          val.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">City</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("current_allotee_city", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Pin</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("current_allotee_pin", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Registry Date</div>
                  <div>
                    <DatePicker
                      onChange={(val) =>
                        changeHandle(
                          "registry_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Basic Allotee *</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("basic_allotee", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Allotment No.</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("allotment_no", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Father/Husband Name</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle(
                          "basic_allotee_father_husband",
                          val.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Landline No.</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("land_line_no", val.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="rounded-md px-5 py-4 bg-white mt-4">
              <div className="text-lg font-semibold">Other Details</div>
              <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-5 mt-4">
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Land Use</div>
                  <div>
                    <Select
                      defaultValue="Select Land Use"
                      onChange={(val) => changeHandle("land_use", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "yes",
                          label: "Yes",
                        },
                        {
                          value: "no",
                          label: "No",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Floor</div>
                  <div>
                    <Select
                      defaultValue="Select Floor"
                      onChange={(val) => changeHandle("floor", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "1st",
                          label: "1st",
                        },
                        {
                          value: "2nd",
                          label: "2nd",
                        },
                        {
                          value: "3rd",

                          label: "3rd",
                        },
                        {
                          value: "4th",
                          label: "4th",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Length</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      text="number"
                      onChange={(val) =>
                        changeHandle("length", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Breadth</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      text="number"
                      onChange={(val) =>
                        changeHandle("breadth", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Terrace Cost</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("terrace_cost", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Extra Land Area</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("extra_land_area", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Creation Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "creation_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Fencing Wall Cost</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("fencing_wall_cost", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Year of Construction</div>
                  <div>
                    <DatePicker
                      onChange={(val) =>
                        changeHandle(
                          "construction_yr",
                          moment(val).format("YYYY")
                        )
                      }
                      picker="year"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Nazool Property</div>
                  <div className=" ">
                    <Radio.Group
                      onChange={(e) => {
                        changeHandle("nazool_property", e.target.value);
                      }}
                    >
                      <Radio value={"yes"}>
                        <span className="font-semibold text-lg">Yes</span>
                      </Radio>
                      <Radio value={"no"}>
                        <span className="font-semibold text-lg">No</span>
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Sector</div>
                  <div>
                    <Select
                      defaultValue="Select Sector"
                      onChange={(val) => changeHandle("sector", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "A",
                          label: "A",
                        },
                        {
                          value: "B",
                          label: "B",
                        },

                        {
                          value: "C",
                          label: "C",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Lease Free Hold</div>
                  <div className=" ">
                    <Radio.Group
                      onChange={(e) => {
                        changeHandle("lease_free_hold", e.target.value);
                      }}
                    >
                      <Radio value={"yes"}>
                        <span className="font-semibold text-lg">Yes</span>
                      </Radio>
                      <Radio value={"no"}>
                        <span className="font-semibold text-lg">No</span>
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Status Flag</div>
                  <div className=" ">
                    <Radio.Group
                      onChange={(e) => {
                        changeHandle("status_flag", e.target.value);
                      }}
                    >
                      <Radio value={"yes"}>
                        <span className="font-semibold text-lg">Yes</span>
                      </Radio>
                      <Radio value={"no"}>
                        <span className="font-semibold text-lg">No</span>
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className=" flex mt-10  space-x-4">
                <div
                  onClick={() => submit(data)}
                  className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                >
                  <span className="text-black font-semibold text-lg px-2">
                    Submit
                  </span>
                </div>
                <div
                  onClick={() => navigate("/manage/manageProperty")}
                  className="bg-gray-100 flex cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-800 text-gray-600 hover:bg-gray-200"
                >
                  <span>
                    <MdCancel className=" font-semibold text-lg mt-1.5" />
                  </span>
                  <span className="  font-semibold text-lg px-2">Cancel</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </SideBarTest>
    </>
  );
};

export default NewProperty;
