import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IconButton } from "@mui/material";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const FAQ = () => {
  const navigate = useNavigate();

  const [faqData, setFaqData] = useState([]);

  const GetData = () => {
    axios
      .get(
        `hri_admin/help-faq-list?help_section=${localStorage.getItem(
          "help-type"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("FAQ data: ", res.data);
        setFaqData(res.data);
      })
      .catch((err) => {
        console.log("Error Loading data", err.response.data);
      });
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <div>
      <div className="flex justify-start bg-white px-5 py-3 m-4 rounded-md z-10">
        <div className="w-screen">
          <Timeline>
            <p className="text-2xl pb-4 font-semibold text-[#0865B6]">FAQ</p>
            {faqData.map((info) => {
              return (
                <TimelineItem key={info.id} className="before:hidden">
                  <TimelineSeparator>
                    <TimelineDot sx={{ background: "#0865B6" }} />
                    <TimelineConnector sx={{ background: "#0865B6" }} />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="pb-4">
                      <div className="flex flex-row items-center justify-between">
                        <div className="space-y-3 flex-grow">
                          <p className="text-lg font-semibold">
                            Q.{info.question}
                          </p>
                          <p>A.{info.answer}</p>
                        </div>
                        <div>
                          <IconButton
                            onClick={() => {
                              navigate(`edit`);
                              localStorage.setItem("FAQ-QuestionId", info.id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
