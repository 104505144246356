import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, DatePicker, Badge, Input, Select, Drawer } from "antd";
import SideBarTest from "../../../components/SideBarTest";
import { notify } from "../../../App";
import moment from "moment";
import Spinner from "../../Spinner/Spinner";
import { useDropzone } from "react-dropzone";

const { RangePicker } = DatePicker;
const cookies = new Cookies();
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const AdvertisementList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [addvertisement, setAdvertisement] = useState();
  const [scheme, setScheme] = useState();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [advertisementList, setAdvertisementList] = useState();
  const formData = new FormData();
  const options = [];
  const PropertyOptions = [];

  scheme?.map((item) => {
    options.push({
      label: item.name,
      value: item.id,
    });
  });

  properties?.map((item) => {
    PropertyOptions.push({
      label: item.property_name || item.property_no,
      value: item.id,
    });
  });

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const changeHandle = (key, value) => {
    setAdvertisement((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    getBase64(acceptedFiles[0], (url) => {
      setImageUrl(url);
    });
    changeHandle("image", acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "text/html": [".html", ".htm"],
    },
  });

  const getAlladds = () => {
    axios
      .get(`property/advertisement-list`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setAdvertisementList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  const AddAdvertisement = (item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (key === "schemes") {
        formData.append("schemes[]", JSON.stringify(value));
      } else if (key === "property") {
        formData.append("property[]", JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(`property/advertisement-create`, formData, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setLoading(true);
        getAlladds();
        onClose();
        notify({ message: "Advertisement created !", type: "success" });
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  useEffect(() => {
    getAlladds();
  }, [loading]);

  const getPropertyList = () => {
    axios
      .post(
        `property/property-scheme-list`,
        { scheme: addvertisement?.schemes },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        setProperties(res.data);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  useEffect(() => {
    getPropertyList();
  }, [addvertisement?.schemes]);

  useEffect(() => {
    axios
      .get(`property/scheme-list`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setScheme(res.data);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
    getAlladds();
  }, []);
  return (
    <SideBarTest>
      {loading ? (
        <div className="flex justify-center w-full">
          <Spinner />
        </div>
      ) : (
        <div className="">
          <div className=" md:px-10 py-4">
            <Breadcrumb className="text-lg">
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className=" font-semibold ">
                Advertisement
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className=" flex mx-10 justify-end">
            <div
              onClick={showDrawer}
              className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
            >
              <span className="text-black font-semibold flex flex-wrap md:text-lg px-2">
                Create Advertisement
              </span>
            </div>
          </div>
          <div className="bg-white  px-4 md:mx-10 md:px-10 py-4 mt-3 grid lg:grid-cols-2 gap-5  rounded-md">
            {advertisementList?.map((item) => {
              return (
                <div
                  key={item.unique_id}
                  className={
                    "bg-yellow-100 border rounded-md shadow-md  border-gray-200 p-4"
                  }
                >
                  <div className="space-y-2">
                    <div className="text-lg font-semibold flex justify-between">
                      <span>{item.name}</span>
                      <span className="text-sm   bg-white shodow-md px-4 py-1 rounded-xl">
                        {item.unique_id}
                      </span>
                    </div>
                    <div>
                      Start Date : {moment(item.start_date).format("MMM Do YY")}
                    </div>
                    <div className="flex justify-between">
                      <div>
                        Completion Date :{" "}
                        {moment(item.end_date).format("MMM Do YY")}
                      </div>{" "}
                      <div>
                        Schemes :{" "}
                        {item.schemes !== null ? item.schemes.length : "N/A"}
                      </div>
                    </div>
                    <div className="lg:flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 xl:space-x-4 mt-4">
                      <div
                        onClick={() =>
                          navigate(`/advertisementList/${item.id}`)
                        }
                        className="bg-default_yellow cursor-pointer px-4 py-2 shadow-md  w-auto text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                      >
                        <span className="text-black font-semibold text-lg px-2">
                          View Details
                        </span>
                      </div>
                      <div
                        onClick={() => navigate(`/viewApplicant/${item.id}`)}
                        className="bg-default_yellow cursor-pointer px-4 py-2 shadow-md  w-auto text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                      >
                        <span className="text-black font-semibold text-lg px-2">
                          View Applicant
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <Drawer
        title="Add Advertisement"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="space-y-4">
          <p className=" flex">
            <div className="font-semibold mr-3 w-20">Name</div>
            <div>
              <Input
                placeholder="Enter Here"
                onChange={(val) => changeHandle("name", val.target.value)}
              />
            </div>
          </p>
          <p className=" flex">
            <div className="font-semibold mr-3 w-20">Schemes</div>
            <div>
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select"
                onChange={(val) => changeHandle("schemes", val)}
                options={options}
              />
            </div>
          </p>
          <p className=" flex">
            <div className="font-semibold mr-3 w-20">Property</div>
            <div>
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select"
                onChange={(val) => changeHandle("property", val)}
                options={PropertyOptions}
              />
            </div>
          </p>

          <p className=" flex">
            <div className="font-semibold mr-3 w-20">No. of Installments</div>
            <div>
              <Input
                placeholder="Please enter Installments number"
                onChange={(val) =>
                  changeHandle("installment_no", val.target.value)
                }
              />
            </div>
          </p>
          <p className=" flex">
            <div className="font-semibold mr-3 w-20">Start Date</div>
            <div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(val) =>
                  changeHandle("start_date", moment(val).format("YYYY-MM-DD"))
                }
              />
            </div>
          </p>
          <p className=" flex">
            <div className="font-semibold mr-3 w-20">End Date</div>
            <div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(val) =>
                  changeHandle("end_date", moment(val).format("YYYY-MM-DD"))
                }
              />
            </div>
          </p>
          <div className="w-full mt-4">
            <div className="text-lg">Advertisement Image</div>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              {imageUrl ? (
                <p className=" h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                  <img src={imageUrl} className="h-40 " />
                </p>
              ) : (
                <p className="mt-4 h-32 bg-gray-100 rounded-md cursor-pointer border-dashed border-2 border-gray-300 flex justify-center items-center">
                  Drag 'n' drop some files here, or click to select files
                </p>
              )}
            </div>
          </div>
          <p className="mt-6">
            <div
              onClick={() => AddAdvertisement(addvertisement)}
              className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
            >
              <span className="text-black font-semibold text-lg px-2">
                Add Advertisement
              </span>
            </div>
          </p>
        </div>
      </Drawer>
    </SideBarTest>
  );
};
export default AdvertisementList;
