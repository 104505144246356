import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import logo from "../Images/logo.png";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { hiding, selectHeader, showing } from "../features/HeaderSlice";
import Header from "./Header";
import { AiFillPieChart } from "react-icons/ai";
import { HiBriefcase } from "react-icons/hi";
import { RiAdvertisementFill } from "react-icons/ri";
import { BsFileEarmarkCheckFill } from "react-icons/bs";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "universal-cookie";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";

const cookies = new Cookies();

const SideBarTest = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const currentState = useSelector(selectHeader);
  const [visible1, setVisible1] = useState(false);
  const navigate = useNavigate();

  const showDrawer = () => {
    setVisible1(true);
  };

  const onClose = () => {
    setVisible1(false);
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  useEffect(() => {
    handleResize();
  }, []);

  return (
    <>
      <div className={`${!isMobile ? "flex" : null}`}>
        {!isMobile ? (
          <div className="">
            <div className="flex flex-col overflow-auto font-inter mb-8">
              <div className="min-h-full md:flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800">
                <div className=" fixed top-0 flex flex-col   w-60 bg-white h-full border-r">
                  <div className="">
                    <img src={logo} className="pl-4 w-16 mt-3 rounded-full" />
                  </div>
                  <div className="overflow-y-auto overflow-x-hidden flex-grow">
                    <ul className="flex flex-col py-4 ">
                      <li>
                        <div
                          onClick={() => navigate("/dashboard")}
                          className={`relative flex flex-row items-center h-11 cursor-pointer focus:outline-none hover:bg-light_yellow text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-default_yellow pr-6 
                            ${
                              window.location.pathname === "/dashboard"
                                ? "bg-light_yellow border-default_yellow text-gray-700"
                                : ""
                            }`}
                        >
                          <p className=" pt-3 pl-2 font-semibold flex">
                            <AiFillPieChart className="mr-3 text-2xl" />
                            Dashboard
                          </p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/scheme")}
                          className={`relative flex flex-row items-center h-11 focus:outline-none cursor-pointer hover:bg-light_yellow text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-default_yellow pr-6 
                            ${
                              window.location.pathname.includes("/scheme")
                                ? "bg-light_yellow border-default_yellow text-gray-700"
                                : ""
                            }`}
                        >
                          <p className=" pt-3 pl-2 font-semibold flex">
                            <HiBriefcase className="mr-3 text-2xl" />
                            Scheme
                          </p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/manage/manageProperty")}
                          className={`relative flex flex-row items-center h-11 focus:outline-none cursor-pointer hover:bg-light_yellow text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-default_yellow pr-6 
                            ${
                              window.location.pathname.includes("/manage/")
                                ? "bg-light_yellow border-default_yellow text-gray-700"
                                : ""
                            }`}
                        >
                          <p className="pt-3 pl-2 font-semibold flex">
                            <DashboardIcon className="mr-3" />
                            <div>Manage</div>
                          </p>
                        </div>
                      </li>{" "}
                      <li>
                        <div
                          onClick={() => navigate("/advertisementList")}
                          className={`relative flex flex-row items-center h-11 focus:outline-none cursor-pointer hover:bg-light_yellow text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-default_yellow pr-6 
                            ${
                              window.location.pathname.includes(
                                "/advertisementList"
                              )
                                ? "bg-light_yellow border-default_yellow text-gray-700"
                                : ""
                            }`}
                        >
                          <p className=" pt-3 pl-2 font-semibold flex">
                            <RiAdvertisementFill className="mr-3 text-2xl" />
                            Advertisement
                          </p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/apply")}
                          className={`relative flex flex-row items-center h-11 focus:outline-none cursor-pointer hover:bg-light_yellow text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-default_yellow pr-6 
                            ${
                              window.location.pathname
                                .toLowerCase()
                                .includes("apply")
                                ? "bg-light_yellow border-default_yellow text-gray-700"
                                : ""
                            }`}
                        >
                          <p className="pt-3 pl-2 font-semibold flex">
                            <BsFileEarmarkCheckFill className="mr-4 text-lg mt-1" />
                            <div className="">Apply</div>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col font-inter hover:cursor-pointer">
                    {/* <LogoutIcon className="w-8 h-4" /> */}
                    <div
                      onClick={() => {
                        const token = cookies.get("token");
                        cookies.remove("token");
                        signOut(auth)
                          .then(() => {
                            navigate("/");
                          })
                          .catch((error) => {
                            console.error(error);
                          });
                      }}
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-light_yellow text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-default_yellow pr-6"
                    >
                      <p className="m-1">
                        <LogoutIcon className="mr-5" />
                        Logout
                      </p>
                    </div>
                  </div>
                  <div className="px-4 py-4 flex border-t justify-between items-center font-inter hover:cursor-pointer ">
                    <div
                      onClick={() => navigate("/profile")}
                      className="flex gap-4"
                    >
                      <Avatar size={50} src={logo}>
                        RP
                      </Avatar>
                      <div className="grid">
                        <span className="font-medium text-gray-900 w-full max-w-[144px] overflow-hidden whitespace-nowrap text-ellipsis">
                          RIshabh Pandey
                        </span>
                        <span className="text-gray-500">Admin Profile</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* <div className="bg-white flex justify-between py-2">
              <div className="flex   logo-container space-x-2 text-xl px-2">
                <a href="/dashboard">
                  <img
                    src={logo}
                    alt=""
                    style={{ height: 40 }}
                    className="border-2 border-slate-300 p-0.5 rounded-full"
                  />
                </a>
                <p className="font-semibold mt-3 self-center  hidden md:block text-sm md:text-base">
                  Kanpur Development Authority
                </p>
              </div>
              <div className="">
                {currentState?.show === true ? (
                  <IconButton>
                    {" "}
                    <CloseIcon
                      onClick={showDrawer}
                      className="cursor-pointer"
                    />
                  </IconButton>
                ) : (
                  <IconButton>
                    {" "}
                    <MenuIcon onClick={showDrawer} className="cursor-pointer" />
                  </IconButton>
                )}
              </div>
            </div> */}
            <Header />
          </>
        )}

        <div
          className={`${
            !isMobile ? "ml-60" : null
          } bg-default-background w-screen p-4`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default SideBarTest;
