import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumb, DatePicker, Badge, Card, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import SideBarTest from "../../../components/SideBarTest";
import { notify } from "../../../App";
import Spinner from "../../Spinner/Spinner";
import moment from "moment";

const { RangePicker } = DatePicker;
const cookies = new Cookies();

const PropertyDetailsList = () => {
  const navigate = useNavigate();
  const [getPropertyDetails, setPropertyDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const id = window.location.pathname.slice(21);
  const [searchParam] = useSearchParams();
  const type = searchParam.get("type");
  const url =
    type === "Advertisement"
      ? `property/property-ad-list/${id}`
      : `property/property-list/${id}`;
  const getAllData = () => {
    axios
      .get(url, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setPropertyDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <SideBarTest>
      {!loading ? (
        <div className="  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb className="text-lg">
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer"
                onClick={() => {
                  searchParam.get("type") === "Advertisement"
                    ? navigate("/advertisementList")
                    : navigate("/scheme");
                }}
              >
                {searchParam.get("type") === "Advertisement"
                  ? "Advertisement"
                  : "Schemes"}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Property Details List
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="bg-white  md:mx-10 px-6  py-4 mt-3 grid lg:grid-cols-2 gap-5  rounded-md">
            {getPropertyDetails?.map((item) => {
              return (
                <div key={item.id}>
                  <div
                    className={
                      "bg-yellow-100 border rounded-md shadow-md  border-gray-200 p-4"
                    }
                  >
                    <div className="space-y-2">
                      <div className="text-lg font-semibold">
                        {item?.current_allotee
                          ? item?.current_allotee
                          : item?.property_no}
                      </div>
                      <div>
                        Start Date :{" "}
                        {moment(item.start_date).format("MMM Do YY")}
                      </div>
                      <div className="flex justify-between">
                        <div>
                          Completion Date :{" "}
                          {moment(item.completion_date).format("MMM Do YY")}
                        </div>
                        <div>Building Type : {item.building_type}</div>
                      </div>
                      <div className="flex justify-center mt-4">
                        <div
                          onClick={() => {
                            searchParam.get("type") === "Advertisement"
                              ? navigate(
                                  `/propertyDetails/${item.id}?type=Advertisement`
                                )
                              : navigate(`/propertyDetails/${item.id}`);
                          }}
                          className="bg-default_yellow cursor-pointer px-4 py-2 shadow-md  w-auto text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                        >
                          <span className="text-black font-semibold text-lg px-2">
                            View Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-full">
          <Spinner />
        </div>
      )}
    </SideBarTest>
  );
};

export default PropertyDetailsList;
