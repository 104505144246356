import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { InboxOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { MdCancel } from "react-icons/md";
import { Breadcrumb, Table, Drawer, Input, Select } from "antd";
import SideBarTest from "../../../components/SideBarTest";
import moment from "moment";
import { notify } from "../../../App";
import { useMemo } from "react";
import Spinner from "../../Spinner/Spinner";
import { Drawer as Drawer2 } from "antd";
const cookies = new Cookies();

const SchemeDetails = () => {
  const navigate = useNavigate();
  const [getSchemeDetails, setGetSchemeDetails] = useState();
  const [plotDetails, setPlotDetails] = useState();
  const [area, setArea] = useState();
  const id = window.location.pathname.slice(8);
  const [open, setOpen] = useState(false);
  const [openArea, setOpenArea] = useState(false);
  const [addPlot, setAddPlot] = useState({ scheme: id });
  const [loading, setLoading] = useState(true);
  const [addAreaDetails, setAddAreaDetails] = useState({ scheme: id });
  const [isProperty, setIsProperty] = useState(false);

  const changeHandle = (key, value) => {
    setAddPlot((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const changeHandleArea = (key, value) => {
    setAddAreaDetails((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const showDrawerArea1 = () => {
    setOpenArea(true);
  };

  const onCloseArea1 = () => {
    setOpenArea(false);
  };
  const getDetails = () => {
    axios
      .get(`property/scheme-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setGetSchemeDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  const checkProperty = () => {
    const id2 = window.location.pathname.slice(8);
    axios
      .get(`property/property-list/${id2}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        console.log(res.data, "here2");
        if (res.data.length !== 0) {
          navigate(`/propertyDetailsList/${getSchemeDetails?.id}`);
        } else {
          notify({
            message: "Property is not Linked !",
            type: "error",
          });
        }
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  const fetchAreaDetails = () => {
    axios
      .get(`property/scheme-area-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setArea(res.data);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  const fetchPlotDetails = () => {
    axios
      .get(`property/scheme-plot-list/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setPlotDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  const AddPlorRow = () => {
    axios
      .post(`property/scheme-plot-create`, addPlot, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setLoading(true);
        fetchPlotDetails();
        onClose();
        notify({ message: "Plot added !", type: "success" });
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };
  const AddArea = () => {
    axios
      .post(`property/scheme-area-create`, addAreaDetails, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setLoading(true);
        fetchAreaDetails();
        notify({ message: "Area added !", type: "success" });
        onCloseArea1();
      })
      .catch((err) => {
        console.log(err.response);
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  const columns = [
    {
      title: "Land Use",
      dataIndex: "land_use",
      key: "land_use",
    },
    {
      title: "Sq.Meter",
      dataIndex: "square_meter",
      key: "square_meter",
    },
    {
      title: "Acre",
      dataIndex: "acre",
      key: "acre",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
  ];
  const plotColums = [
    {
      title: "Plot No.",
      dataIndex: "plot_no",
      key: "plot_no",
    },
    {
      title: "Type",
      dataIndex: "plot_type",
      key: "plot_type",
    },
    {
      title: "Size(Meter)",
      dataIndex: "plot_size",
      key: "plot_size",
    },
    {
      title: "Serial No.",
      dataIndex: "sl_no",
      key: "sl_no",
    },
  ];
  useEffect(() => {
    getDetails();
    fetchPlotDetails();
    fetchAreaDetails();
  }, []);

  return (
    <>
      <SideBarTest>
        <div className=" h-screen  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/scheme")}
              >
                Schemes
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Scheme Details
              </Breadcrumb.Item>
            </Breadcrumb>
            {loading ? (
              <div className="flex justify-center w-full">
                <Spinner />
              </div>
            ) : (
              <>
                <div className=" bg-light_yellow  flex justify-between py-3 px-5  mt-4 font-semibold text-lg rounded-t-md">
                  <span>Scheme Details</span>
                  <div
                    onClick={checkProperty}
                    className="hover:bg-gray-400 cursor-pointer px-4 py-1 w-auto shadow-md text-center text-white rounded-md hover:text-gray-600 bg-gray-500"
                  >
                    <span className=" font-semibold  px-2">View Property</span>
                  </div>
                </div>
                <div className="bg-white   px-10 py-4 pb-5 font-semibold text-gray-500 rounded-b-md">
                  <div className="grid lg:grid-cols-2 xl:grid-cols-3   text-lg gap-x-10 gap-y-5 mt-4">
                    <div className="grid grid-cols-2">
                      <div className="">Scheme Name</div>
                      <div className="text-gray-700">
                        {getSchemeDetails?.name}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Zone</div>
                      <div className="text-gray-700">
                        {getSchemeDetails?.zone}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Ward No.</div>
                      <div className="text-gray-700">
                        {getSchemeDetails?.ward}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div>Estimate Cost</div>
                      <div className="text-gray-700">
                        {getSchemeDetails?.estimate_cost}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Work Details</div>
                      <div className="text-gray-700">
                        {getSchemeDetails?.work_details}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Start Date</div>
                      <div className="text-gray-700">
                        {moment(getSchemeDetails?.start_date).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div>Completion Date</div>
                      <div className="text-gray-700">
                        {moment(getSchemeDetails?.completion_date).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div>Sanction Date</div>
                      <div className="text-gray-700">
                        {moment(getSchemeDetails?.sanction_date).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div>Tender Date</div>
                      <div className="text-gray-700">
                        {moment(getSchemeDetails?.tender_date).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </div>

                    <div className="grid grid-cols-2 ">
                      <div>Progress Status</div>
                      <div
                        className={`${
                          getSchemeDetails?.progress_status === "ongoing"
                            ? "bg-blue-500"
                            : "bg-green-500"
                        } rounded-lg px-2 shadow-md text-center text-white py-1`}
                      >
                        {getSchemeDetails?.progress_status.toUpperCase()}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="mt-4 h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                      <img src={getSchemeDetails?.map} className="h-40 " />
                    </p>
                  </div>
                  <div className="font-semibold flex justify-between text-lg mt-10 mb-2 text-gray-700">
                    <div className="">Area Details</div>
                    <div
                      onClick={showDrawerArea1}
                      className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                    >
                      <span className="text-black font-semibold text-lg px-2">
                        Add Area
                      </span>
                    </div>
                  </div>
                  <Table
                    dataSource={area}
                    pagination={false}
                    columns={columns}
                  />
                  <div className="font-semibold flex justify-between text-lg mt-10 mb-2 text-gray-700">
                    <div className="">Plot Details</div>
                    <div
                      onClick={showDrawer}
                      className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                    >
                      <span className="text-black font-semibold text-lg px-2">
                        Add Plot
                      </span>
                    </div>
                  </div>

                  <Table
                    dataSource={plotDetails}
                    pagination={false}
                    columns={plotColums}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <Drawer
          title="Add Plot"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <div className="space-y-4">
            <p className=" flex">
              <div className="font-semibold mr-3 w-20">Plot No.</div>
              <div>
                <Input
                  type="number"
                  placeholder="Enter Here"
                  onChange={(val) => changeHandle("plot_no", val.target.value)}
                />
              </div>
            </p>
            <p className=" flex">
              <div className="font-semibold mr-3 w-20">Type</div>
              <div>
                <Select
                  defaultValue="Select Work Details"
                  onChange={(val) => changeHandle("plot_type", val)}
                  className="rounded-md"
                  options={[
                    {
                      value: "A",
                      label: "A",
                    },
                    {
                      value: "B",
                      label: "B",
                    },
                    {
                      value: "C",
                      label: "C",
                    },
                  ]}
                />
              </div>
            </p>
            <p className=" flex">
              {" "}
              <div className="font-semibold mr-3 w-20">Size(Meter)</div>
              <div>
                <Input
                  type="number"
                  placeholder="Enter Here"
                  onChange={(val) =>
                    changeHandle("plot_size", val.target.value)
                  }
                />
              </div>
            </p>
            <p className=" flex">
              <div className="font-semibold mr-3 w-20">Serial No.</div>
              <div>
                <Input
                  type="number"
                  placeholder="Enter Here"
                  onChange={(val) => changeHandle("sl_no", val.target.value)}
                />
              </div>
            </p>
            <p className="mt-6">
              <div
                onClick={AddPlorRow}
                className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
              >
                <span className="text-black font-semibold text-lg px-2">
                  Add Plot
                </span>
              </div>
            </p>
          </div>
        </Drawer>
        <Drawer2
          title="Add Area"
          placement="right"
          onClose={onCloseArea1}
          open={openArea}
        >
          <div className="space-y-4">
            <p className=" flex">
              <div className="font-semibold mr-3 w-20">Land Use</div>
              <div>
                <Input
                  placeholder="Enter Here"
                  onChange={(val) =>
                    changeHandleArea("land_use", val.target.value)
                  }
                />
              </div>
            </p>
            <p className=" flex">
              <div className="font-semibold mr-3 w-20">Sq.Meter</div>
              <div>
                <Input
                  type="number"
                  placeholder="Enter Here"
                  onChange={(val) =>
                    changeHandleArea("square_meter", val.target.value)
                  }
                />
              </div>
            </p>
            <p className=" flex">
              <div className="font-semibold mr-3 w-20">Acre</div>
              <div>
                <Input
                  type="number"
                  placeholder="Enter Here"
                  onChange={(val) => changeHandleArea("acre", val.target.value)}
                />
              </div>
            </p>
            <p className=" flex">
              {" "}
              <div className="font-semibold mr-3 w-20">Percentage</div>
              <div>
                <Input
                  type="number"
                  placeholder="Enter Here"
                  onChange={(val) =>
                    changeHandleArea("percentage", val.target.value)
                  }
                />
              </div>
            </p>
            <p className="mt-6">
              <div
                onClick={AddArea}
                className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
              >
                <span className="text-black font-semibold text-lg px-2">
                  Add Area
                </span>
              </div>
            </p>
          </div>
        </Drawer2>
      </SideBarTest>
    </>
  );
};

export default SchemeDetails;
