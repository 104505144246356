import { ResponsiveBar } from "@nivo/bar";
import { useEffect } from "react";
import { useState } from "react";
export const BarChart = ({ data, width }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["Live Cases", "Cases", "Cases1"]}
      indexBy="country"
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "yellow_orange_red" }}
      borderRadius={5}
      // borderColor={{ theme: "background" }}
      axisTop={null}
      axisRight={null}
      height={200}
      width={width ? width : 450}
      label={false}
    />
  );
};
