import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Card, CardContent, IconButton, Modal } from "@mui/material";
import ReactPlayer from "react-player";
import DeleteIcon from "@mui/icons-material/Delete";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const Tutorial = () => {
  const navigate = useNavigate();

  const [tutorialdata, setTutorialData] = useState([]);

  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalUrl, setModalUrl] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const GetTutorialList = () => {
    axios
      .get(
        `hri_admin/help-tutorial-list?help_section=${localStorage.getItem(
          "help-type"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        setTutorialData(res.data);
        console.log("The Tutorial data is", res.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  useEffect(() => {
    GetTutorialList();
  }, []);

  return (
    <div>
      <div className="bg-white px-5 py-3 m-4 rounded-md z-10">
        <p className="text-2xl p-2 font-semibold text-[#0865B6]">Tutorials</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {tutorialdata?.map((info) => (
            <Card
              key={info.id}
              className="my-4"
              sx={{ background: "#EDEBEE" }}
              elevation={0}
            >
              <CardContent>
                <div className="flex flex-row">
                  <p className="flex-grow font-bold text-xl">{info.title}</p>
                  <div className="gap-2">
                    <IconButton
                      onClick={() => {
                        navigate("edit");
                        localStorage.setItem("TutorialId", info.id);
                      }}
                    >
                      <EditIcon sx={{ color: "#0865B6", fontSize: "20px" }} />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon sx={{ color: "#0865B6", fontSize: "23px" }} />
                    </IconButton>
                  </div>
                </div>
                <p className="text-lg mb-1">{info.description}</p>
                <video
                  className="mt-3"
                  onClick={() => {
                    handleOpen();
                    setModalTitle(info.title);
                    setModalDescription(info.description);
                    setModalUrl(info.tutorial_video);
                  }}
                >
                  <source src={info.tutorial_video} type="video/mp4" />
                </video>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="flex justify-center items-center"
                >
                  <Box className="w-10/12 p-3 border-solid border-[#FFF] rounded-md bg-white lg:w-1/2 lg:p-5">
                    <p className="text-lg md:text-2xl font-bold">
                      {modalTitle}
                    </p>

                    <p className="text-base md:text-xl mb-4">
                      {modalDescription}
                    </p>
                    <ReactPlayer
                      url={modalUrl}
                      className="react-player"
                      // playing
                      width="100%"
                      height="100%"
                      controls
                    />
                  </Box>
                </Modal>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
