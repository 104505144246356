/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { BsFillFolderFill } from "react-icons/bs";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const cookies = new Cookies();

const ManageMaster = () => {
  const navigate = useNavigate();
  const propertyMaster = [
    {
      name: "New Proposal",
      link: "newProposal",
    },
  ];
  const quotaMaster = [
    {
      name: "View Quota",
      link: "viewQuota",
    },
    {
      name: "New Quota",
      link: "newQuota",
    },
  ];
  const otherChargesMaster = [
    {
      name: "View Other Charges",
      link: "viewOtherCharges",
    },
    {
      name: "New Charges",
      link: "newCharges",
    },
  ];
  const buildingType = [
    {
      name: "View Building Type",
      link: "viewBuildingType",
    },
    {
      name: "New Building Type",
      link: "newBuildingType",
    },
  ];
  const propertyType = [
    {
      name: "View Property Type",
      link: "viewPropertyType",
    },
    {
      name: "New Property Type",
      link: "newPropertyType",
    },
  ];
  const proposalSchemeProperty = [
    {
      name: "Proposal Scheme Mapping",
      link: "proposalSchemeMapping",
    },
    {
      name: "Proposal Property Mapping",
      link: "proposalPropertyMapping",
    },
  ];
  const fundSourceDetail = [
    {
      name: "View Source Fund",
      link: "viewSourceFund",
    },
    {
      name: "Define Source Fund",
      link: "defineSourceFund",
    },
    {
      name: "View Fund Details",
      link: "viewFundDetails",
    },
    {
      name: "New Fund Details",
      link: "newFundDetails",
    },
    {
      name: "Property Last Three Inst Pending",
      link: "propertyLastThreeInstPending",
    },
  ];

  return (
    <>
      <div className="bg-default-background pb-10 px-3 md:px-5 w-full h-screen ">
        <div
          style={{ marginBottom: "20px" }}
          className="bg-white rounded-md px-3 md:px-5 py-4 "
        >
          <div className="flex">
            <BsFillFolderFill className="text-xl mt-1" />
            <div className="font-semibold md:text-lg ml-2">Property Master</div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {propertyMaster.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">Quota Master</div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {quotaMaster.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">
              Other Charges Master
            </div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {otherChargesMaster.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">Building Type</div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {buildingType.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">Property Type</div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {propertyType.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">
              Proposal Scheme Property
            </div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {proposalSchemeProperty.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="flex mt-5">
            <BsFillFolderFill className="text-lg md:text-xl mt-.5 md:mt-1" />
            <div className="font-semibold md:text-lg ml-2">
              Fund Source Detail
            </div>
          </div>
          <div className="grid  md:grid-cols-2 gap-x-4 gap-y-5 mt-3 ">
            {fundSourceDetail.map((item) => {
              return (
                <div
                  className="text-center cursor-pointer text-gray-600 hover:text-black hover:bg-default_yellow font-semibold md:text-lg py-2.5 rounded-md bg-light_yellow"
                  key={item.name}
                  onClick={() => {
                    toast.info("Will be coming soon !!", {
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      style: {
                        backgroundColor: "#FFFDD7",
                        color: "black",
                        fontWeight: "bold",
                      },
                      icon: false,
                      progressStyle: { backgroundColor: "#FFE247" },
                    });
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageMaster;
