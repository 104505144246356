import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Modal } from "antd";
import Cookies from "universal-cookie";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Result,
  DatePicker,
  Input,
  Radio,
  Select,
} from "antd";
import SideBarTest from "../../../components/SideBarTest";
import { notify } from "../../../App";
import { MdCancel } from "react-icons/md";
import moment from "moment";

const cookies = new Cookies();

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const ApplyForm = () => {
  const navigate = useNavigate();
  const id = window.location.pathname.slice(17);
  const [data, setData] = useState({ advertisement: id });
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [advertisementList, setAdvertisementList] = useState([]);
  const formData = new FormData();

  const changeHandle = (key, value) => {
    setData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    getBase64(acceptedFiles[0], (url) => {
      setImageUrl(url);
    });
    changeHandle("image", acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "text/html": [".html", ".htm"],
    },
  });
  const getAllData = () => {
    axios
      .get(`property/advertisement-detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setAdvertisementList(res.data.schemes);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const submit = (item) => {
    Object.entries(item).forEach(([key, value]) => {
      console.log(key, value, "here");
      formData.append(key, value);
    });

    axios
      .post(`property/user-application-create`, formData, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        navigate("/scheme");
        notify({ message: "Form Submited !!", type: "success" });
        handleCancel();
      })
      .catch((err) => {
        err.response.data.errors.scheme.forEach((message) => {
          notify({ message: message, type: "error" });
        });
      });
    handleCancel();
  };
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <SideBarTest>
        <div className=" h-screen  ">
          <div className=" md:px-10 py-4">
            <Breadcrumb className="text-lg">
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer "
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="hover:text-black font-semibold cursor-pointer"
                onClick={() => navigate("/apply")}
              >
                Apply
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-semibold">
                Apply Form
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className=" bg-light_yellow  py-3 px-5  mt-4 font-semibold text-lg rounded-t-md">
              Apply Form
            </div>
            <div className="bg-white   px-10 py-4  rounded-b-md">
              <div className="grid lg:grid-cols-2 xl:grid-cols-3  gap-x-10 gap-y-5 mt-4">
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Select Scheme </div>
                  <div>
                    <Select
                      defaultValue="Select Scheme"
                      onChange={(val) => changeHandle("scheme", val)}
                      className="rounded-md"
                      options={advertisementList?.map((item) => {
                        return {
                          value: item.id,
                          label: item?.name,
                        };
                      })}
                    />
                  </div>
                </div>
                {/* <div className="grid grid-cols-2 ">
                  <div className="text-lg">Select Advertisement </div>
                  <div>
                    <Select
                      defaultValue="Select Scheme"
                      onChange={(val) => changeHandle("advertisement", val)}
                      className="rounded-md"
                      options={advertisementList?.map((item) => {
                        return {
                          value: item.id,
                          label: item?.name,
                        };
                      })}
                    />
                  </div>
                </div> */}
                <div className="grid grid-cols-2">
                  <div className="text-lg">Tppe of Plot</div>
                  <div>
                    <Select
                      defaultValue="Select Plot Type"
                      onChange={(val) => changeHandle("plot_type", val)}
                      className="rounded-md"
                      options={[
                        {
                          value: "2_BAK",
                          label: "2 BAK",
                        },
                        {
                          value: "3_BAK",
                          label: "3 Bak",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Name of Applicant</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) => changeHandle("name", val.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Father / Husband Name</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("father_husband", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Age</div>
                  <div>
                    <Input
                      type="number"
                      placeholder="Enter Here "
                      onChange={(val) => changeHandle("age", val.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Sex</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) => changeHandle("sex", val.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="text-lg">Caste</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("caste", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Permanent Address</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("permanent_address", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">
                    <div>Pin Code</div>
                    <div className="text-gray-400">(Permanent Address)</div>
                  </div>

                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("permanent_pin", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Current Address</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("current_address", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">
                    <div>Pin Code</div>
                    <div className="text-gray-400">(Current Address)</div>
                  </div>

                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("current_pin", val.target.value)
                      }
                    />
                  </div>
                </div>
                {/* 
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Profession Name</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("reg_amount", val.target.value)
                      }
                    />
                  </div>
                </div> */}
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Registration Amount</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("reg_amount", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Payment / DD</div>
                  <div>
                    <Radio.Group
                      onChange={(e) => {
                        changeHandle("nazool_property", e.target.value);
                      }}
                    >
                      <Radio value={true}>
                        <span className="font-semibold text-lg">Yes</span>
                      </Radio>
                      <Radio value={false}>
                        <span className="font-semibold text-lg">No</span>
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Date</div>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        changeHandle(
                          "dd_date",
                          moment(val).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Bank Name</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("bank_name", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Applicant Bank A/C No.</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("bank_acount", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Bank Name & Address</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("bank_address", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">IFSC / RTGC</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("bank_rtgs_ifsc", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Pan Number</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) => changeHandle("pan", val.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Aadhaar Number</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("aadhaar", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Annual Income</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("annual_income", val.target.value)
                      }
                    />
                  </div>
                </div>
                {/* <div className="grid grid-cols-2 ">
                  <div className="text-lg">Pin</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      onChange={(val) =>
                        changeHandle("current_allotee_pin", val.target.value)
                      }
                    />
                  </div>
                </div> */}
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Phone Number</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("phone_no", val.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Mob. No.</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="number"
                      onChange={(val) =>
                        changeHandle("mob_no", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 ">
                  <div className="text-lg">Email</div>
                  <div>
                    <Input
                      placeholder="Enter Here"
                      type="email"
                      onChange={(val) =>
                        changeHandle("email", val.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-4 space-x-4">
                <div className="text-lg mr-5">Applied Flat No. </div>
                <div>
                  <Input
                    placeholder="Enter Here"
                    onChange={(val) =>
                      changeHandle("first_preference", val.target.value)
                    }
                  />
                  <div className="font-semibold text-gray-500 mt-1">
                    (First Prefrence)
                  </div>
                </div>
                <div>
                  <Input
                    placeholder="Enter Here"
                    onChange={(val) =>
                      changeHandle("second_preference", val.target.value)
                    }
                  />
                  <div className="font-semibold text-gray-500 mt-1">
                    (Secound Prefrence)
                  </div>
                </div>
                <div>
                  <Input
                    placeholder="Enter Here"
                    onChange={(val) =>
                      changeHandle("third_preference", val.target.value)
                    }
                  />
                  <div className="font-semibold text-gray-500 mt-1">
                    (Third Prefrence)
                  </div>
                </div>
              </div>
              <div className="w-full mt-4 ">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="text-lg">Applicant Image</div>

                  {imageUrl ? (
                    <p className="mt-4 h-40 bg-gray-100 cursor-pointer rounded-md border-dashed border-2 border-gray-300 flex justify-center items-center">
                      <img src={imageUrl} className="h-40 " />
                    </p>
                  ) : (
                    <p className="mt-4 h-32 bg-gray-100 rounded-md cursor-pointer border-dashed border-2 border-gray-300 flex justify-center items-center">
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex mt-10  space-x-4">
                <div
                  //   onClick={() => submit(data)}
                  onClick={showModal}
                  className="bg-yellow-100 cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-600 hover:bg-yellow-200"
                >
                  <span className="text-black font-semibold text-lg px-2">
                    Submit
                  </span>
                </div>
                <div
                  onClick={() => navigate("/manage/manageProperty")}
                  className="bg-gray-100 flex cursor-pointer px-4 py-2 w-auto shadow-md text-center rounded-md hover:text-gray-800 text-gray-600 hover:bg-gray-200"
                >
                  <span>
                    <MdCancel className=" font-semibold text-lg mt-1.5" />
                  </span>
                  <span className="  font-semibold text-lg px-2">Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBarTest>
      <Modal open={isModalOpen} footer={false} onCancel={handleCancel}>
        <Result
          title="Are you sure you want to pay &#8377;1000"
          extra={[
            <Button onClick={() => submit(data)} type="primary" key="console">
              Apply
            </Button>,
            <Button nClick={handleCancel} key="buy">
              Cancle
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
};

export default ApplyForm;
